import { Route, Navigate, Router, Routes } from "react-router-dom";
import { Groups } from "../Groups/Groups";
import { Programs } from "../Programs/Programs";
import { Layout } from "./Layout";
import { ClientsRoute } from "../Clients/ClientsRoute";

export const ManageRouter = () => {
  return (
    <Layout>
      <Routes>
        <Route path="programs" element={<Programs />} />
        <Route path="clients/*" element={<ClientsRoute />} />
        <Route path="groups" element={<Groups />} />

        <Route path="*" element={<Navigate to="clients" replace />} />
        {/* <Route path="teams/:slug" element={<Team />} /> */}
      </Routes>
    </Layout>
  );
};
