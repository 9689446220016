import * as Avatar from "@radix-ui/react-avatar";

import styles from "./Header.module.scss";

import "../Avatar.scss";
import { Link } from "react-router-dom";
import { ReactNode, useState } from "react";
import { Account } from "components/Layers/Sheet_Account/Account";

import { useGetSelfQuery } from "graphql/types";

const initials = (source: string) => {
  return source
    .split(" ")
    .map((v) => v.trim().substring(0, 1))
    .filter(Boolean)
    .join("")
    .toUpperCase();
};

export interface HeaderProps {
  backHref?: string;
  more?: ReactNode;
}

export const Header = ({ backHref, more }: HeaderProps) => {
  const { data, loading } = useGetSelfQuery({
    fetchPolicy: "cache-first",
  });

  const name = data?.self.name || data?.self.email || "";

  const [open, setOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div>
        {backHref && (
          <Link className={styles.back} to={backHref}>
            <i className="ri-arrow-left-line"></i>
          </Link>
        )}
      </div>

      <div className="row" style={{ gap: "1rem" }}>
        {more}

        <Avatar.Root
          className="AvatarRoot"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Avatar.Image
            className="AvatarImage"
            src={data?.self.photo}
            alt={name}
          />
          <Avatar.Fallback className="AvatarFallback" delayMs={500}>
            {initials(name)}
          </Avatar.Fallback>
        </Avatar.Root>
      </div>

      <Account open={open} onOpen={setOpen} />
    </header>
  );
};
