import { Avatar } from "components/Avatar/Avatar";
import { useState } from "react";

export interface AccountProps {
  open: boolean;
  onOpen: (v: boolean) => void;
}

export interface SheetAvatarProps {
  loading?: boolean;
  url: string;
  username?: string;
  accept?: string;
  onChange: (file: File) => Promise<void> | void;
}

export const SheetAvatar = ({
  loading: loadingExternal = false,
  username = "",
  url = "",
  accept = "image/*",
  onChange,
}: SheetAvatarProps) => {
  const [loading, setLoading] = useState(false);

  return (
    <label>
      <Avatar
        loading={loadingExternal || loading}
        photo={url}
        name={username}
        size={96}
      />

      <input
        style={{ display: "none" }}
        accept={accept}
        type="file"
        onChange={async (event) => {
          setLoading(true);

          const file = event.target.files?.[0];

          if (file) {
            await onChange?.(file);
          }

          setLoading(false);
        }}
      />
    </label>
  );
};
