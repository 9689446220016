import { IReps, RepetitionsType } from "./types";

export const Reps = {
  equals(a: IReps, b: IReps): boolean {
    return a.type === b.type && a.value === b.value;
  },

  nominal(value: number = 0): IReps {
    return { type: RepetitionsType.Units, value };
  },

  duration(value: number = 0): IReps {
    return { type: RepetitionsType.Duration, value };
  },
};
