export const HomePrograms = () => {
  return (
    <>
      <div style={{ padding: "2rem 1rem 1rem" }}>
        <h2>Clients</h2>
      </div>

      <div style={{ padding: "2rem 1rem 1rem" }}>
        <h2>Team</h2>
      </div>

      <div style={{ padding: "2rem 1rem 1rem" }}>
        <h2>Shared plans</h2>
      </div>
    </>
  );
};
