import { getExerciseName } from "../../../data/Exercises";
import { Variables } from "../../../lib/_variables";
import { IWorkoutCycle } from "../../../lib/_workout";
import { IVariable } from "../../../lib/types";

import styles from "./Variables.module.scss";

export interface VariablesComponentProps {
  workout: IWorkoutCycle;
  onVariableUpdate: (id: string, value: number) => void;
}

export const VariablesComponent = ({
  workout,
  onVariableUpdate,
}: VariablesComponentProps) => {
  let variables: IVariable[] = [];

  if (Array.isArray(workout.variables)) {
    variables = workout.variables.filter((variable) =>
      Variables.used(workout.program.exercises, variable)
    );
  }

  if (variables.length === 0) {
    return null;
  }

  return (
    <div className="row" style={{ gap: "0.5rem", flexWrap: "wrap" }}>
      {variables.map((variable) => (
        <div
          key={variable.id}
          className={styles.variable}
          onClick={() => {
            let response = prompt("New variable value");

            if (response) {
              const number = Number.parseFloat(response);

              if (!Number.isNaN(number)) {
                onVariableUpdate(variable.id, number);
              }
            }
          }}
        >
          <div className={styles.variableTitle}>
            {getExerciseName(variable.exercise)}
          </div>
          <div className={styles.variableValue}>{variable.value}</div>
        </div>
      ))}
    </div>
  );
};
