import { ReactNode } from "react";
import styles from "./Card.module.scss";
import clsx from "clsx";

interface CardProps {
  className?: string;
  children: ReactNode;
  progress: number;
}

export const Card = ({ children, progress, className }: CardProps) => (
  <div
    className={clsx(styles.component, 100 - progress < 1 && styles.complete)}
  >
    <div
      className={styles.background}
      style={{
        width: progress + "%",
        borderTopRightRadius: 100 - progress + "px",
        borderBottomRightRadius: 100 - progress + "px",
      }}
    />
    <div className={clsx(styles.content, className)}>{children}</div>
  </div>
);
