import clsx from "clsx";
import { PropsWithChildren } from "react";

import styles from "./Label.module.scss";

export interface LabelProps extends PropsWithChildren {
  done: boolean;
  extra: boolean;
  modified: boolean;
  onClick: () => void;
}

export const Label = ({
  extra,
  modified,
  done,
  children,
  onClick,
}: LabelProps) => {
  return (
    <div
      className={clsx(
        styles.component,
        extra && styles.extra,
        modified && styles.modified,
        done && styles.done
      )}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        onClick();
      }}
    >
      {children}
    </div>
  );
};
