import { useState } from "react";
import styles from "./SettingsRow.module.scss";
import $ from "clsx";
import { repeat } from "../../utils";

export type Props = {
  label: string;
  description?: string;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
};

export const SettingsNumberRow = ({
  label,
  min,
  max,
  value,
  description,
  onChange,
}: Props) => {
  return (
    <div className={styles.component}>
      <div className={$(styles.title)}>{label}</div>

      <div style={{ flex: 1 }} />
      <div className={styles.numbers}>
        {repeat(max - min + 1).map((index) => {
          const number = min + index;

          return (
            <div
              key={index}
              className={$(styles.number, value === number && styles.active)}
              onClick={() => {
                onChange(number);
              }}
            >
              {number}
            </div>
          );
        })}
      </div>
    </div>
  );
};
