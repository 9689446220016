import clsx from "clsx";
import styles from "./SheetInput.module.scss";

export interface SheetInputProps {
  value: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (value: string) => void;
}

export const SheetInput = ({
  placeholder,
  value,
  disabled,
  onChange,
}: SheetInputProps) => {
  return (
    <input
      className={clsx("input-reset", styles.input)}
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
