import { ReactNode, forwardRef } from "react";
import styles from "./InputButton.module.scss";

export interface InputButtonProps {
  children?: ReactNode;
}

export const InputButton = forwardRef<HTMLDivElement, InputButtonProps>(
  ({ children, ...rest }, ref) => (
    <div role="button" className={styles.button} ref={ref} {...rest}>
      {children}
    </div>
  )
);
