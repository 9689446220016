import { User } from "firebase/auth";
import { db } from "../lib/firebase";
import {
  doc,
  addDoc,
  collection,
  getDocs,
  query,
  limit,
  where,
  and,
} from "firebase/firestore";
import { useAsync } from "react-use";
import { createIndexedConverter } from "utils";
import { useCallback } from "react";
// import {} from 'fireba'
// import {} from "react-firebase-hooks";

export interface ITeam {
  id?: string;
  name: string;
  roles: Record<string, string>;
}

export interface ITeamInput {
  name: string;
  photoUrl: string;
}

export const Converter = createIndexedConverter<ITeam>();

export const createTeam = async (input: ITeamInput, user: User) => {
  const team = {
    ...input,
    roles: {
      [user.uid]: "owner",
    },
  };

  const response = await addDoc(collection(db, `/teams`), team);

  return response;
};

const LOADING = [null, true, null] as const;

export const useTeams = (user: User | null) => {
  return useAsync(async () => {
    if (!user) return null;

    const snapshot = await getDocs(
      query(
        collection(db, `/teams`),
        where(`roles.${user.uid}`, "in", ["member", "owner"])
      ).withConverter(Converter)
    );

    return snapshot.docs.map((v) => v.data());
  }, [user]);
};

export const inviteToTeam = (user: User) => {
  //
  return useCallback(async () => {}, [user]);
};
