import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../lib/firebase";
import { User, signInAnonymously } from "firebase/auth";

interface AuthenticatedWrapperProps {
  children: React.ReactNode;
}

export interface AuthenticatedContextValue {
  user: User;
  setUser: (v: User | null) => void;
}

const AuthenticatedContext = createContext<AuthenticatedContextValue | null>(
  null
);

export const AuthenticatedWrapper: React.FC<AuthenticatedWrapperProps> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(auth.currentUser);

  useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        console.log("Auth:\n", user);

        setUser(user);
      } else {
        console.log("Auth: none");
        await signInAnonymously(auth);
      }
    });
  }, []);

  if (!user) {
    return null;
  }

  return (
    <AuthenticatedContext.Provider value={{ user, setUser }}>
      {children}
    </AuthenticatedContext.Provider>
  );
};

export const useAuthenticatedWrapper = () => {
  const context = useContext(AuthenticatedContext);

  if (!context) {
    throw new Error("Context is undefined");
  }

  return context;
};
