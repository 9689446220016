import { keyBy } from "lodash"

export const getExerciseName = (id: string) => {
  return exerciseNames.find((v) => v.id === id)?.name ?? ""
}

export const getExercise = (id: string) => {
  return exerciseNames.find((v) => v.id === id)
}

export type DBExercise = {
  id: string
  name: string
  weights: boolean
  measures?: string[]
}

export const exerciseNames: DBExercise[] = [
  { id: "abRollerExercise", name: "Ab Roller Exercise", weights: false },
  { id: "abs", name: "Abs", weights: false },
  { id: "barbellCurl", name: "Barbell Curl", weights: true },
  { id: "benchPress", name: "Bench Press", weights: true },
  { id: "bentOverRow", name: "Bent Over Row", weights: true },
  { id: "bicepCurl", name: "Bicep Curl", weights: true },
  { id: "bicycleCrunches", name: "Bicycle Crunches", weights: false },
  { id: "boxJump", name: "Box Jump", weights: false },
  { id: "burpees", name: "Burpees", weights: false },
  { id: "cableCross", name: "Cable Cross", weights: false },
  { id: "calfRaises", name: "Calf Raises", weights: true },
  { id: "chestFly", name: "Chest Fly", weights: true },
  { id: "deadlift", name: "Deadlift", weights: true },
  { id: "dumbbellCurl", name: "Dumbbell Curl", weights: true },
  { id: "dumbbellPress", name: "Dumbbell Press", weights: true },
  { id: "dumbbellRows", name: "Dumbbell Rows", weights: true },
  { id: "facePull", name: "Face Pull", weights: false },
  { id: "farmersWalk", name: "Farmer's Walk", weights: false },
  { id: "frontSquat", name: "Front Squat", weights: true },
  { id: "hammerCurl", name: "Hammer Curl", weights: true },
  { id: "hipThrust", name: "Hip Thrust", weights: true },
  { id: "jumpRope", name: "Jump Rope", weights: false },
  { id: "kettlebellSwing", name: "Kettlebell Swing", weights: true },
  { id: "latPulldown", name: "Lat Pulldown", weights: false },
  { id: "lateralRaise", name: "Lateral Raise", weights: true },
  { id: "legCurl", name: "Leg Curl", weights: true },
  { id: "legExtension", name: "Leg Extension", weights: false },
  { id: "legPress", name: "Leg Press", weights: true },
  { id: "lunges", name: "Lunges", weights: false },
  { id: "medicineBallSlam", name: "Medicine Ball Slam", weights: false },
  { id: "mountainClimbers", name: "Mountain Climbers", weights: false },
  {
    id: "plank",
    name: "Plank",
    weights: false,
    measures: ["duration"]
  },
  {
    id: "pullUp",
    name: "Pull-Up",
    weights: false,
    measures: ["reps"]
  },
  {
    id: "pushUp",
    name: "Push-Up",
    weights: false,
    measures: ["reps"]
  },
  { id: "reverseFly", name: "Reverse Fly", weights: true },
  { id: "russianTwist", name: "Russian Twist", weights: false },
  { id: "seatedRow", name: "Seated Row", weights: true },
  { id: "shoulderPress", name: "Shoulder Press", weights: true },
  { id: "sitUps", name: "Sit-Ups", weights: false },
  { id: "skullCrushers", name: "Skull Crushers", weights: false },
  { id: "squat", name: "Squat", weights: true },
  { id: "treadmillRunning", name: "Treadmill Running", weights: false },
  { id: "tricepDip", name: "Tricep Dip", weights: false },
  { id: "inclineBenchPress", name: "Incline Bench Press", weights: true },
  { id: "oneArmDumbbellRow", name: "One Arm Dumbbell Row", weights: true },
  { id: "seatedBarbellPress", name: "Seated Barbell Press", weights: true },
  {
    id: "thkr",
    name: "Twisting Hanging Knee Raise",
    weights: true,
    measures: ["reps"]
  },
  {
    id: "running",
    name: "Running",
    weights: false,
    measures: ["duration", "distance"]
  },
  {
    id: "dumbbellBenchPress",
    name: "Dumbbell Bench Press",
    weights: true,
    measures: ["reps"]
  }
]

export const exerciseDB = keyBy(exerciseNames, "id")
