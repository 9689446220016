import { getExerciseName } from "../../../data/Exercises";
import { IVariable, SetStatus } from "../../../lib/types";
import { Card } from "./Card";
import styles from "./WorkoutExercise.module.scss";
import clsx from "clsx";
import { lib } from "../../../lib/_lib";
import { IWorkoutExercise, IWorkoutSet } from "../../../lib/_workout";
import { Label } from "./Label";

export interface WorkoutExerciseProps {
  exercise: IWorkoutExercise;
  sets: IWorkoutSet[];
  variables: IVariable[];
  onSetStatusChange: (set: IWorkoutSet, value: SetStatus) => void;
  onSetClick: (set: IWorkoutSet) => void;
  onSetCreate: () => void;
}

export const WorkoutExercise = ({
  exercise,
  sets,
  variables,
  onSetStatusChange,
  onSetClick,
  onSetCreate,
}: WorkoutExerciseProps) => (
  <Card progress={lib.WorkoutSet.progress(sets)}>
    <div className={styles.header} id={`exercise-title-${exercise.id}`}>
      <div className={styles.title}>{getExerciseName(exercise.name)}</div>
      <div className={styles.more}>
        <button
          className="button button--secondary button--small"
          onClick={() => onSetCreate()}
        >
          <i className="ri-add-line"></i>
        </button>
      </div>
    </div>

    <div className={styles.sets} id={`exercise-sets-${exercise.id}`}>
      {sets.map((set, index) => {
        const done = set.status === SetStatus.Done;
        const modified = lib.WorkoutSet.modified(set);
        const label = lib.WorkoutSet.getLabel(set, variables);

        return (
          <div
            key={set.id}
            className={clsx(styles.set, done && styles.done)}
            onClick={() => {
              onSetStatusChange(set, done ? SetStatus.None : SetStatus.Done);
            }}
          >
            <div className={styles.icon} style={{ fontSize: 24 }}>
              <i
                className={
                  done
                    ? "ri-checkbox-circle-line"
                    : "ri-checkbox-blank-circle-line"
                }
              />
            </div>

            <div className={styles.name}>Set {index + 1}</div>
            <div style={{ flex: 1 }} />
            <Label
              done={done}
              modified={modified}
              extra={set.extra}
              onClick={() => {
                onSetClick(set);
              }}
            >
              {label}
            </Label>
            {/* <div
              className={clsx(
                styles.reps,
                set.extra && styles.extra,
                modified && styles.modified
              )}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                onSetClick(set);
              }}
            >
              {label}
            </div> */}
          </div>
        );
      })}
    </div>
  </Card>
);
