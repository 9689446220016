import { ReactNode } from "react"

import styles from "./SheetButton.module.scss"
import { clsx } from "clsx"

export interface SheetButtonProps {
  children: ReactNode
  use: "normal" | "positive" | "action" | "neutral"
  disabled?: boolean
  onClick?: () => void
}

export const SheetButton = ({
  disabled,
  children,
  onClick,
  use
}: SheetButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={() => onClick?.()}
      className={clsx(styles.button, styles[use])}
    >
      {children}
    </button>
  )
}
