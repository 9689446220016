import ReactDOM from "react-dom/client"

import "./index.css"
import "./app/components.css"
import "remixicon/fonts/remixicon.css"

import reportWebVitals from "./reportWebVitals"
import { BrowserRouter, Route, Routes } from "react-router-dom"

import { Root } from "./routes/Root/Root"
import { Workout } from "./routes/Workout/Workout"
import { Program } from "./routes/Program"
import { AuthenticatedWrapper } from "./app/AuthenticatedWrapper"
import { Start } from "./routes/Start"
import { Signup } from "./routes/Signup"
import { Schedule } from "./routes/Schedule"
import { Manage } from "./routes/Manage"
import { ApolloProvider } from "@apollo/client"
import { apolloClient } from "graphql/apollo"
import { Home } from "routes/Home"
import { Session } from "routes/Session/Session"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <ApolloProvider client={apolloClient}>
    <AuthenticatedWrapper>
      <BrowserRouter>
        <Routes>
          <Route path="/start" element={<Start />} />
          <Route path="/programs">
            <Route path=":id" element={<Program />} />
          </Route>
          <Route path="/schedule">
            <Route path=":id" element={<Schedule />} />
          </Route>
          <Route path="/signup" element={<Signup />} />
          <Route path="/workouts/:id/*" element={<Workout />} />
          <Route path="/sessions/:id/*" element={<Session />} />
          <Route path="/manage/*" element={<Manage />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Root />} />
        </Routes>
      </BrowserRouter>
    </AuthenticatedWrapper>
  </ApolloProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
