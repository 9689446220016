import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import "../../components/Menu.scss";

export interface Props {
  onClick: (id: string) => void;
}

export const WorkoutMenu = ({ onClick }: Props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="MenuIconButton" aria-label="Customise options">
          <i className="ri-equalizer-2-line"></i>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className="DropdownMenuContent"
          sideOffset={5}
        >
          {/* <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => {
              onClick("start-new-cycle");
            }}
          >
            Workout plan
          </DropdownMenu.Item> */}

          {/* <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => {
              onClick("start-new-cycle");
            }}
          >
            Settings <div className="RightSlot">⌘+S</div>
          </DropdownMenu.Item> */}

          {/* <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => {
              onClick("start-new-cycle");
            }}
          >
            History
          </DropdownMenu.Item> */}

          <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => {
              onClick("start-new-cycle");
            }}
          >
            Start new cycle
          </DropdownMenu.Item>

          <DropdownMenu.Arrow className="DropdownMenuArrow" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
