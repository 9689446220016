import { useState } from "react";
import { Menu2 } from "components/Menu2";
import { NumericUncontrolledInput } from "components/NumericUncontrolledInput";
import { InputButton } from "../InputButton";
import { InputContainer } from "../InputContainer";

import styles from "./InputNumber.module.scss";

export interface Option {
  value: string;
  label: string;
}

export interface InputNumberProps {
  input: number;
  min?: number;
  max?: number;
  inputDisabled?: (input: number, option: string) => boolean;
  option: string;
  options: Option[];
  onInputChange: (input: number) => void;
  onOptionChange: (option: string) => void;
}

export const InputNumber = ({
  min,
  max,
  input,
  inputDisabled,
  option,
  options,
  onInputChange,
  onOptionChange,
}: InputNumberProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <InputContainer focused={focused}>
      <NumericUncontrolledInput
        min={min}
        max={max}
        value={input}
        disabled={inputDisabled?.(input, option)}
        className={styles.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onInputChange}
      />

      <Menu2
        align="start"
        items={options.map((option) => {
          return {
            label: option.label,
            id: option.value,
          };
        })}
        onSelect={(id) => {
          onOptionChange(id);
        }}
      >
        <InputButton>
          {options.find((v) => v.value === option)?.label}{" "}
          <i className="ri-arrow-drop-down-line"></i>
        </InputButton>
      </Menu2>
    </InputContainer>
  );
};
