import { ReactNode, forwardRef } from "react";
import styles from "./ResistanceButton.module.scss";

export interface ResistanceButtonProps {
  children?: ReactNode;
}

export const ResistanceButton = forwardRef<
  HTMLDivElement,
  ResistanceButtonProps
>(({ children, ...rest }, ref) => {
  return (
    <div className={styles.button} ref={ref} {...rest}>
      {children}
    </div>
  );
});
