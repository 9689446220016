import { ApolloLink } from "@apollo/client";
import { auth } from "lib/firebase";
/**
 * Sets headers on every request
 */
export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext((request: any) => {
    // @ts-ignore
    const token = auth.currentUser?.accessToken;

    const headers: Record<string, string> = {
      ...request.headers,
    };

    if (token) {
      headers["authorization"] = `Bearer ${token}`;
    }

    return {
      headers,
    };
  });

  return forward(operation).map((data) => {
    return data;
  });
});
