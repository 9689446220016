import { exerciseNames } from "data/Exercises";
import Fuse, { IFuseOptions } from "fuse.js";
import styles from "./SearchSheet.module.scss";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { SheetButton } from "components/Layer/SheetButton";
import { Sheet } from "components/Layer/Sheet";
import { SheetContent } from "components/Layer/SheetContent";

const fuseOptions: IFuseOptions<(typeof exerciseNames)[0]> = {
  keys: ["name"],
};

const fuse = new Fuse(exerciseNames, fuseOptions);
const limit = 10;

type Exercise = (typeof exerciseNames)[0];

const Empty = () => {
  return <div>Nothing was found</div>;
};

export interface SearchSheetProps {
  open: boolean;
  onSelect: (exerciseKey: string) => void;
  onOpen: (value: boolean) => void;
}

export const SearchSheet = ({ open, onOpen, onSelect }: SearchSheetProps) => {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState<Exercise[]>([]);
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    setQuery("");
    setSelected("");
  }, [open]);

  useEffect(() => {
    if (query.length === 0) {
      setResults(exerciseNames.slice(0, limit));
    } else {
      setResults(fuse.search(query, { limit }).map((v) => v.item));
    }
  }, [query]);

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        <div className={styles.container}>
          <div>
            <input
              className={clsx("input-reset", styles.input)}
              placeholder="Search..."
              value={query}
              onChange={(event) => setQuery(event.target.value)}
            />
          </div>

          <div className={styles.listContainer}>
            <div className={styles.list}>
              {results.map((exercise) => {
                return (
                  <div
                    className={clsx(
                      styles.button,
                      exercise.id === selected && styles.selected
                    )}
                    key={exercise.id}
                    onClick={() => {
                      setSelected(exercise.id);
                    }}
                  >
                    {exercise.name}
                  </div>
                );
              })}
            </div>
          </div>

          <div style={{ flex: 1 }} />

          <div
            style={{
              width: "100%",
              paddingTop: "1rem",
            }}
          >
            <SheetButton
              disabled={!selected}
              use="normal"
              onClick={() => {
                onSelect(selected);
                onOpen(false);
              }}
            >
              Next
            </SheetButton>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
