import {
  GetGroupsDocument,
  useCreateGroupMutation,
  useGetGroupsQuery,
} from "graphql/types";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

import "react-loading-skeleton/dist/skeleton.css";

import styles from "./GroupSelector.module.scss";
import { OpenableProps, Sheet } from "components/Layer/Sheet";
import { SheetButton } from "components/Layer/SheetButton";
import { useEffect, useState } from "react";
import { SheetAvatar } from "components/Layer/Sheet_Avatar/Account";
import { SheetFooter } from "components/Layer/SheetFooter";
import { SheetInput } from "components/Layers/Sheet_Input/SheetInput";
import { SheetContent } from "components/Layers/Sheet_Content/SheetContent";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "lib/firebase";
import { nanoid } from "nanoid";
import { Avatar } from "components/Avatar/Avatar";

const Loading = () => (
  <div className={styles.container}>
    <span style={{ pointerEvents: "none" }}>
      <div className={styles.body}>
        <div className={styles.icon}>
          <Skeleton className={styles.loadingIcon} />
        </div>
        <div style={{ flex: 1 }}>
          <div className={styles.title}>
            <Skeleton width={100} />
          </div>
          <div className={styles.description}>
            <Skeleton width={200} />
          </div>
        </div>
      </div>
    </span>
  </div>
);

export interface NewProps {
  onClick?: () => void;
}

export const New = ({ onClick }: NewProps) => (
  <div
    role="button"
    className={clsx(styles.container, styles.new, styles.clickable)}
    onClick={(event) => {
      event.preventDefault();
      onClick?.();
    }}
  >
    <span style={{ pointerEvents: "none" }}>
      <div className={styles.body}>
        <div className={styles.icon}>
          <i className="ri-add-line"></i>
        </div>
        <div className={styles.text}>
          <div className={styles.title}>Create new team</div>
          <div className={styles.description}>
            Quickly start sharing something
          </div>
        </div>
      </div>
    </span>
  </div>
);

export interface ItemProps {
  photo: string;
  title: string;
  description: string;
  onClick?: () => void;
}

const Item = ({ photo, title, description, onClick }: ItemProps) => (
  <div
    role="button"
    className={clsx(styles.container, styles.clickable)}
    onClick={(event) => {
      event.preventDefault();
      onClick?.();
    }}
  >
    <span style={{ pointerEvents: "none" }}>
      <div className={styles.body}>
        <div className={styles.icon}>
          <Avatar size={34} radius={6} photo={photo} name={title} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
      </div>
    </span>
  </div>
);

export interface CreateGroupSheetProps extends OpenableProps {
  onAction: (data: { name: string; photo: string }) => void | Promise<void>;
}

const CreateGroupSheet = ({
  open,
  onOpen,
  onAction,
}: CreateGroupSheetProps) => {
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");

  useEffect(() => {
    setName("");
    setPhoto("");
  }, [open]);

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        <SheetAvatar
          username="Test"
          url={photo}
          onChange={async (file) => {
            if (!file) return;

            const extension = file.name.split(".").pop();

            const storageRef = ref(
              storage,
              `/uploads/${nanoid()}.${extension}`
            );

            await uploadBytes(storageRef, file);

            const url = await getDownloadURL(storageRef);

            setPhoto(url);
          }}
        />

        <SheetInput placeholder="Group name" value={name} onChange={setName} />
      </SheetContent>
      <SheetFooter>
        <SheetButton
          use="action"
          onClick={async () => {
            try {
              await onAction({ name, photo });
              onOpen(false);
            } catch (error) {
              console.warn(error);
            }
          }}
        >
          Create group
        </SheetButton>
      </SheetFooter>
    </Sheet>
  );
};

export interface GroupSelectorProps {}

export const GroupSelector = (props: GroupSelectorProps) => {
  const { data, loading } = useGetGroupsQuery({
    fetchPolicy: "cache-first",
    nextFetchPolicy: "cache-and-network",
  });

  const [createGroupMutation] = useCreateGroupMutation();

  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [name, setName] = useState("");

  if (loading) {
    return <Loading />;
  }

  const memberships = data?.memberships ?? [];

  if (!loading && memberships.length > 0) {
    const first = memberships[0];

    return (
      <>
        {memberships.map((v) => {
          return (
            <Item
              key={v.group.id}
              title={v.group.name}
              photo={v.group.photo}
              description="Something"
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <New
        onClick={() => {
          setCreateGroupOpen(true);
        }}
      />
      <CreateGroupSheet
        open={createGroupOpen}
        onOpen={setCreateGroupOpen}
        onAction={async (event) => {
          await createGroupMutation({
            variables: {
              input: {
                name: event.name,
                photo: event.photo,
                description: "",
              },
            },

            refetchQueries: [
              {
                query: GetGroupsDocument,
              },
            ],

            awaitRefetchQueries: true,
          });
        }}
      />
    </>
  );
};
