import { round } from "lodash";
import { ISet, IVariable, ResistanceType, VariableType } from "./types";

export const computeNominalResistance = (
  set: ISet,
  variables?: IVariable[]
) => {
  if (set.weight.type !== ResistanceType.Percentage) {
    return set.weight.value;
  }

  const variable = variables?.find(
    (v) => v.type === VariableType.RepetitionMax
  );

  if (!variable) {
    console.warn("Cannot find variable for 1 rep max.");
    return 0;
  }

  const result = round((variable.value / 100) * set.weight.value, 2);

  if (Number.isNaN(result)) {
    return 0;
  }

  return result;
};
