import { round } from "lodash";
import {
  IResistance,
  ResistanceType,
  ISet,
  IVariable,
  VariableType,
} from "./types";

export const Weight = {
  equals(a: IResistance, b: IResistance): boolean {
    return a.type === b.type && a.value === b.value;
  },

  /**
   * Returns a Weight object with kilogram value resistance
   * @param value - Weight in kilograms
   */
  nominal(value: number): IResistance {
    return { type: ResistanceType.Units, value };
  },

  notApplicable(): IResistance {
    return { type: ResistanceType.NotApplicable, value: 0 };
  },

  bodyweight(): IResistance {
    return { type: ResistanceType.Bodyweight, value: 0 };
  },

  computeNominal(set: ISet, variables?: IVariable[]) {
    if (set.weight.type !== ResistanceType.Percentage) {
      return set.weight.value;
    }

    const variable = variables?.find(
      (v) => v.type === VariableType.RepetitionMax
    );

    if (!variable) {
      console.warn("Cannot find variable for 1 rep max.");
      return 0;
    }

    const result = round((variable.value / 100) * set.weight.value, 2);

    if (Number.isNaN(result)) {
      return 0;
    }

    return result;
  },
};

export const Resistance = Weight;
