import styles from "./SearchSheet.module.scss"
import { useState } from "react"
import clsx from "clsx"
import { SheetButton } from "../../../../components/Layer/SheetButton"

interface Props {
  onCancel: () => void
  onCreate: (name: string) => void
}

export const AddExerciseContent = ({ onCancel, onCreate }: Props) => {
  const [name, setName] = useState("")

  return (
    <div className={styles.container}>
      <div>
        <input
          className={clsx("input-reset", styles.input)}
          placeholder="Exercise name"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          width: "100%",
          paddingTop: "1rem"
        }}
      >
        <SheetButton
          use="neutral"
          onClick={() => {
            onCancel()
          }}
        >
          Cancel
        </SheetButton>

        <SheetButton
          disabled={name.length === 0}
          use="action"
          onClick={() => {
            onCreate(name)
          }}
        >
          Create exercise
        </SheetButton>
      </div>
    </div>
  )
}
