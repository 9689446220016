import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { createIndexedConverter } from "../utils";
import { useAsync } from "react-use";
import { useCallback } from "react";
import { IWorkout } from "../lib/types";
import { ProgramConverter } from "./Program.db";
import { createWorkoutCycle } from "../lib/_workout";

export const WorkoutConverter = createIndexedConverter<IWorkout>();

export const useGetWorkouts = (user: User | null) => {
  return useAsync(async () => {
    if (!user) return;

    const snapshot = await getDocs(
      collection(db, `/spaces/${user!.uid}/workouts`).withConverter(
        WorkoutConverter
      )
    );

    const workouts = snapshot.docs.map((doc) => doc.data());

    return workouts;
  }, [user]);
};

export const useCreateWorkout = (user: User | null) => {
  return useCallback(
    async (programId: string, name: string) => {
      if (!user) return;
      if (!programId) return;

      const programSnapshot = await getDoc(
        doc(db, `/programs/${programId}`).withConverter(ProgramConverter)
      );

      if (!programSnapshot.exists()) {
        console.warn("Program doesnt exist");
        return;
      }

      const program = programSnapshot.data();

      const workoutCycle = createWorkoutCycle(
        program.exercises,
        program.variables
      );

      await addDoc(collection(db, `/workout_cycles`), {
        name,
        ...workoutCycle,
        userId: user.uid,
        variables: program.variables,
        programId: program.id,
        programVersion: program.version ?? 0,
        ownerId: user.uid,
        program, // maybe store program copy for now?
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    },
    [user]
  );
};
