import { ReactNode } from "react";
import styles from "./SheetContent.module.scss";
import clsx from "clsx";

export interface SheetContentProps {
  gap?: string | number;
  children?: ReactNode;
}

export const SheetContent = ({ gap, children }: SheetContentProps) => {
  return (
    <div className={clsx(styles.container, "sheet-content")}>{children}</div>
  );
};
