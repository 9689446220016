import { format, isToday, isThisWeek, isThisYear } from "date-fns";

export const formatDate = (date: Date) => {
  if (isToday(date)) {
    return "Today's";
  }

  if (isThisWeek(date)) {
    return format(date, "EEEE") + "'s";
  }

  if (isThisYear(date)) {
    return format(date, "LLLL do");
  }

  return format(date, "LLLL do, yyyy");
};
