import styles from "./SettingsRow.module.scss";
import $ from "clsx";

export type Props = {
  label: string;
  description?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
};

export const SettingsRow = ({
  label,
  value,
  description,
  placeholder,
  onChange,
}: Props) => {
  return (
    <div className={styles.component}>
      <div className={$(styles.title)}>{label}</div>

      <div style={{ flex: 1 }} />
      <div>
        <input
          style={{ flex: 1 }}
          className="set-input"
          placeholder={placeholder}
          value={value}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        />
      </div>
    </div>
  );
};
