import styles from "./Home.module.scss";
import { useGetProgramsQuery } from "graphql/types";
import { GroupSelector } from "./TeamSelector/GroupSelector";
import { HomePrograms } from "./HomePrograms/HomePrograms";
import { Avatar } from "components/Avatar/Avatar";

export const Home = () => {
  const programs = useGetProgramsQuery();

  console.log(programs);

  return (
    <div className={styles.layout}>
      <div className={styles.sidebar}>
        <GroupSelector />
      </div>
      <div>
        <HomePrograms />

        <div className={styles.container}>
          {programs.data?.programs.map((program) => (
            <div className={styles.item} key={program.id}>
              <div>{program.name}</div>
              <div style={{ flex: 1 }} />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingBottom: "0.25rem",
                  paddingTop: "0.25rem",
                }}
              >
                <div
                  className="row"
                  style={{ gap: "0.5rem", alignItems: "center" }}
                >
                  <div className={styles.name}>{program.owner.name}</div>
                  <Avatar
                    size={18}
                    photo={program.owner.photo}
                    name={program.owner.name}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
