import { User } from "firebase/auth";
import { Link, useParams } from "react-router-dom";
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper";
import { getExerciseName } from "../../data/Exercises";
import { ISet, ResistanceType } from "../../lib/types";
import { PageSection } from "../../app/PageSection";
import { repeat } from "../../utils";
import type { ProgramType } from "../../db/Program.db";
import { AppPage } from "../../app/AppPage/AppPage";

import styles from "./ViewProgram.module.scss";

export const Schedule = () => {
  const { id } = useParams();
  const { user } = useAuthenticatedWrapper();

  // if (program.value) {
  //   return <Base id={program.value.id} user={user} program={program.value} />;
  // }

  return null;
};

const prepareLabel = (set: ISet) => {
  let reps = `${set.reps.value} reps`;

  let weight = "N/A";

  if (set.weight.type === ResistanceType.Bodyweight) {
    weight = "Bodyweight";
  }

  if (set.weight.type === ResistanceType.Percentage) {
    weight = `${set.weight.value} % of 1RM`;
  }

  if (set.weight.type === ResistanceType.Units) {
    weight = `${set.weight.value}kg`;
  }

  return [reps, weight] as const;
};

export interface BaseProps {
  id: string;
  user: User | null;
  program: ProgramType;
}

function Base({ program }: BaseProps) {
  const { name, id, weeks, days, exercises } = program;

  return (
    <AppPage backHref="/">
      <PageSection label={name + " schedule"}>
        <div className="row" style={{ gap: "0.5rem", justifyContent: "end" }}>
          <button
            className="button button--small button--secondary"
            onClick={() => {
              alert("Forking workouts & plans is coming soon.");
            }}
          >
            Fork
          </button>

          <Link to={`/start?i_program=${id}`} className="button button--small">
            Use program
          </Link>
        </div>
        <div className={styles.schedule}>
          {repeat(weeks).map((_, weekIndex) => (
            <div key={weekIndex} style={{ marginTop: "1rem" }}>
              {repeat(days).map((_, dayIndex) => {
                const items = exercises
                  .map((v, index) => {
                    return {
                      index,
                      ...v,
                    };
                  })
                  .filter((v) => v.week === weekIndex && v.day === dayIndex);

                if (items.length === 0) {
                  return null;
                }

                return (
                  <div className={styles.day} key={dayIndex}>
                    <div className={styles.dayTitle}>
                      Week {weekIndex + 1} - Day {dayIndex + 1}
                    </div>

                    <div className="card" style={{ padding: "10px 1rem" }}>
                      {items.map((exercise) => {
                        if (exercise.sets.length === 0) {
                          return null;
                        }

                        return (
                          <div className={styles.exercise} key={exercise.id}>
                            <div className={styles.name}>
                              {getExerciseName(exercise.name)}
                            </div>
                            <ul className={styles.list}>
                              {exercise.sets.map((set) => {
                                const [reps, weight] = prepareLabel(set);

                                return (
                                  <li className={styles.entry} key={set.id}>
                                    <span className={styles.left}>{reps}</span>
                                    {/* <div className={styles.dots}></div> */}
                                    <span className={styles.right}>
                                      {weight}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </PageSection>
    </AppPage>
  );
}
