import { DOMAttributes, ReactNode, forwardRef } from "react";
import styles from "./InputButton.module.scss";
import clsx from "clsx";

export interface InputButtonProps extends DOMAttributes<HTMLDivElement> {
  size?: "small" | "medium";
  children?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  // onHold?: () => void;
}

export const InputButton = forwardRef<HTMLDivElement, InputButtonProps>(
  ({ size = "small", children, disabled, ...rest }, ref) => {
    return (
      <div
        role="button"
        className={clsx(
          styles.button,
          styles[size],
          disabled && styles.disabled
        )}
        ref={ref}
        {...rest}
        onContextMenuCapture={(e) => {
          e.preventDefault();
        }}
      >
        {children}
      </div>
    );
  }
);
