import { useEffect, useState } from "react"
import { ProgressLine } from "../../components/ProgressLine/ProgressLine"
import { WorkoutExercise } from "./WorkoutExercise/WorkoutExercise"
import { Party } from "./Party"
import { AppPage } from "../../app/AppPage/AppPage"
import { PageSection } from "../../app/PageSection"
import { SetStatus } from "../../lib/types"
import { WorkoutAddSetWrapper } from "./WorkoutAddSetWrapper"
import { WorkoutEditSetWrapper } from "./WorkoutEditSetWrapper"
import { useWorkout } from "../../lib/_session"
import { lib } from "../../lib/_lib"
import { useParams } from "react-router-dom"
import { VariablesComponent } from "./Variables"
// import { CreateExtraExercise } from "workout_modals/CreateExtraExercise/CreateExtraExercise";
import { SearchSheet } from "./SearchSheet/SearchSheet"
import { useGetSessionQuery, useUpdateSessionMutation } from "graphql/types"
import {
  ISessionExercise,
  ISessionLog,
  ISessionSet,
  IVariable
} from "./schemas"

export const Session = () => {
  const [updateSessionMutation] = useUpdateSessionMutation()

  const { id = "" } = useParams()

  const { data, loading } = useGetSessionQuery({
    variables: {
      id
    },
    skip: !id
  })

  const session = data?.session

  if (!session) {
    return null
  }

  return (
    <Base
      id={id}
      data={session.log}
      onUpdate={async (log) => {
        await updateSessionMutation({
          variables: {
            id,
            input: {
              log
            }
          }
        })
      }}
    />
  )
}

export interface BaseProps {
  id: string
  data: {
    variables: IVariable[]
    exercises: ISessionExercise[]
  }
  onUpdate: (value: ISessionLog) => void
}

const counts = (sets: ISessionSet[]) => {
  const complete = sets.filter((v) => v.status === SetStatus.Done).length
  const total = sets.length

  return [complete, total, 100 * (complete / total)] as const
}

const Base = ({ data, onUpdate }: BaseProps) => {
  const [added, setAdded] = useState({ exerciseId: "" })
  const [edited, setEdited] = useState({ exerciseId: "", setId: "" })
  const [showCreateExtraExercise, setShowCreateExtraExercise] = useState(false)

  const { state, createExercise, createSet, updateSet, updateVariable } =
    useWorkout(data)

  useEffect(() => {
    if (state) {
      onUpdate(state)
    }
  }, [JSON.stringify(state)])

  const [party, setParty] = useState(false)

  const [completed, total, progress] = counts(
    state.exercises.flatMap((v) => v.sets)
  )

  const isDone = completed === total && completed !== 0

  return (
    <AppPage backHref="/">
      {isDone && party && (
        <Party
          onComplete={() => {
            setParty(false)
          }}
        />
      )}

      {/* <PageSection> */}
      <VariablesComponent
        exercises={state.exercises}
        variables={state.variables}
        onVariableUpdate={updateVariable}
      />

      <div style={{ gap: "1rem", display: "flex", flexDirection: "column" }}>
        {state.exercises.length > 0 && (
          <ProgressLine
            backgroundColor="#f3f3f3"
            visualParts={[
              {
                color: "#50ce46",
                percentage: progress.toFixed(2) + "%"
              }
            ]}
          />
        )}

        {state.exercises.map((exercise) => (
          <WorkoutExercise
            key={exercise.id}
            exercise={exercise}
            variables={lib.Variables.filter(
              state.variables as any[],
              exercise.name
            )}
            onSetStatusChange={(set, status) => {
              updateSet(exercise.id, set.id, {
                status
              })

              setParty(true)
            }}
            onSetClick={(set) => {
              setEdited({ exerciseId: exercise.id, setId: set.id })
            }}
            onSetCreate={() => {
              setAdded({ exerciseId: exercise.id })
            }}
          />
        ))}
      </div>

      <div
        style={{
          padding: "2rem",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <button
          className="button button--secondary"
          style={{ lineHeight: "1.5rem" }}
          onClick={() => {
            setShowCreateExtraExercise(true)
          }}
        >
          Add an exercise
        </button>
      </div>
      {/* </PageSection> */}

      <WorkoutAddSetWrapper
        variables={state.variables}
        exercise={state.exercises.find((v) => v.id === added.exerciseId)!}
        onClose={() => {
          setAdded({ exerciseId: "" })
        }}
        onAdd={(exercise, weight, reps) => {
          createSet({
            weight,
            reps,
            exerciseId: exercise.id
          })

          setAdded({ exerciseId: "" })
        }}
      />

      {edited.exerciseId && (
        <WorkoutEditSetWrapper
          variables={state.variables}
          set={
            state.exercises
              .find((v) => v.id === edited.exerciseId)!
              .sets.find((v) => v.id === edited.setId)!
          }
          onClose={() => {
            setEdited({
              exerciseId: "",
              setId: ""
            })
          }}
          onEdit={(overwriteWeight, overwriteReps) => {
            updateSet(edited.exerciseId, edited.setId, {
              overwriteWeight,
              overwriteReps
            })

            setEdited({
              exerciseId: "",
              setId: ""
            })
          }}
        />
      )}

      <SearchSheet
        open={showCreateExtraExercise}
        onOpen={setShowCreateExtraExercise}
        onSelect={(name) => {
          createExercise(name)
        }}
      />
    </AppPage>
  )
}
