import { InputStepper } from "components/Inputs/InputStepper/InputStepper";
import { useState } from "react";
import { SheetButton } from "../../../components/Layer/SheetButton";
import { Sheet } from "components/Layer/Sheet";
import { SheetContent } from "components/Layer/SheetContent";

export interface ParametersSheetProps {
  initialWeight?: number;
  initialReps?: number;
  open: boolean;
  onOpen: (value: boolean) => void;
  onUpdate: (weight: number, reps: number) => void;
}

export const ParametersSheet = ({
  initialWeight,
  initialReps,
  open,
  onOpen,
  onUpdate,
}: ParametersSheetProps) => {
  const [weight, setWeight] = useState(initialWeight ?? 0);
  const [reps, setReps] = useState(initialReps ?? 0);

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        <div
          className="ui-stack"
          style={{
            justifyContent: "center",
            gap: "1.5rem",
            alignItems: "center",
            paddingTop: "0.5rem",
          }}
        >
          <div
            className="ui-stack"
            style={{
              justifyContent: "center",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <h3>Weight</h3>
            <InputStepper
              fill
              size="medium"
              min={0}
              max={800}
              value={weight}
              onChange={setWeight}
            />
          </div>

          <div
            className="ui-stack"
            style={{
              justifyContent: "center",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <h3>Reps</h3>

            <InputStepper
              fill
              size="medium"
              min={0}
              max={800}
              value={reps}
              onChange={setReps}
            />
          </div>
          <div
            style={{
              width: "100%",
              paddingTop: "0.5rem",
            }}
          >
            <SheetButton
              use="positive"
              onClick={() => {
                onUpdate(weight, reps);
                onOpen(false);
              }}
            >
              Save changes
            </SheetButton>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
