import { Sheet } from "components/Layer/Sheet";
import { storage } from "lib/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { SheetContent } from "components/Layer/SheetContent";
import { SheetButton } from "components/Layer/SheetButton";
import { Avatar } from "components/Avatar/Avatar";
import clsx from "clsx";
import { useGetSelfQuery, useUpdateSelfMutation } from "graphql/types";

import styles from "./Account.module.scss";
export interface AccountProps {
  open: boolean;
  onOpen: (v: boolean) => void;
}

export const Account = ({ open, onOpen }: AccountProps) => {
  const [name, setName] = useState<string>("");
  const [updateSelfMutation] = useUpdateSelfMutation();

  const { data, loading } = useGetSelfQuery({
    onCompleted(data) {
      setName(data.self.name);
    },
  });

  if (!data) {
    return null;
  }

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        <div
          className="ui-stack"
          style={{
            paddingTop: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <label>
            <Avatar photo={data.self.photo} name={name} size={96} />

            <input
              style={{ display: "none" }}
              accept="image/*"
              type="file"
              onChange={async (event) => {
                const file = event.target.files?.[0];

                if (!file) return;

                const extension = file.name.split(".").pop();

                const storageRef = ref(
                  storage,
                  `/users/${data.self.uid}/avatar.${extension}`
                );

                await uploadBytes(storageRef, file);

                const photo = await getDownloadURL(storageRef);

                await updateSelfMutation({
                  variables: {
                    input: {
                      photo,
                    },
                  },
                });
              }}
            />
          </label>

          <div className={styles.pad}>
            <input
              className={clsx("input-reset", styles.input)}
              placeholder="Name"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          <SheetButton
            use="action"
            onClick={async () => {
              await updateSelfMutation({
                variables: {
                  input: {
                    name,
                  },
                },
              });

              onOpen(false);
            }}
          >
            Update profile
          </SheetButton>
        </div>
      </SheetContent>
    </Sheet>
  );
};
