import { PropsWithChildren } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";

import styles from "./Sheet.module.scss";

export interface OpenableProps {
  open: boolean;
  onOpen: (open: boolean) => void;
}
export interface SheetProps extends OpenableProps, PropsWithChildren {}

export const Sheet = ({ open, onOpen, children }: SheetProps) => (
  <Dialog.Root open={open} onOpenChange={onOpen}>
    <Dialog.Portal>
      <Dialog.Overlay className={styles.overlay}>
        <Dialog.Content
          autoFocus={false}
          onOpenAutoFocus={(event) => {
            event.preventDefault();
          }}
          className={clsx(styles.content, "sheet")}
        >
          {children}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  </Dialog.Root>
);
