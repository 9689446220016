import { createGenericContext } from "context/createGenericContext"
import { IProgram } from "lib/types"
import { useBuilderState } from "lib/useBuilderState"
import { ReactNode } from "react"

const [useContext, ProvideContext] =
  createGenericContext<ReturnType<typeof useBuilderState>>()

interface Props {
  children: ReactNode
  initial: IProgram
}

export const ProgramContextProvider = ({ children, initial }: Props) => {
  const value = useBuilderState(initial)

  return <ProvideContext value={value}>{children}</ProvideContext>
}

export const useProgramContext = useContext
