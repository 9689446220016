import clsx from "clsx";
import styles from "./Tabs.module.scss";

export type OptionValue = string | number;

export interface Option {
  value: OptionValue;
  label: string;
}

export interface TabsProps {
  options: Option[];
  value: OptionValue;
  onChange: (value: OptionValue) => void;
}

export const Tabs = ({ options, value, onChange }: TabsProps) => {
  return (
    <div className={styles.component}>
      {options.map((option) => {
        return (
          <div
            key={option.value}
            className={clsx(
              styles.option,
              value === option.value && styles.selected
            )}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
