import { ReactNode } from "react";
import styles from "./SheetFooter.module.scss";
import clsx from "clsx";

export interface SheetFooterProps {
  children?: ReactNode;
}

export const SheetFooter = ({ children }: SheetFooterProps) => {
  return (
    <div className={clsx(styles.container, "sheet-footer")}>{children}</div>
  );
};
