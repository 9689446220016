import { useState } from "react";
import { ResistanceButton } from "../../../components/Button_Resistance/ResistanceButton";
import { Menu2 } from "../../../components/Menu2";
import styles from "./Resistance.module.scss";
import clsx from "clsx";
import { NumericUncontrolledInput } from "../../../components/NumericUncontrolledInput";

const resistanceOptions = [
  {
    id: "v",
    label: "Kilograms",
  },
  {
    id: "p",
    label: "% of 1RM",
  },
  {
    id: "b",
    label: "Bodyweight",
  },
  {
    id: "n",
    label: "Not applicable",
  },
];

export interface ResistanceProps {
  value: number;
  type: string;
  onValueChange: (value: number) => void;
  onTypeChange: (type: string) => void;
}

export const Resistance = ({
  value,
  type,
  onTypeChange,
  onValueChange,
}: ResistanceProps) => {
  const [focused, setFocused] = useState(false);

  const disabled = ["b", "n"].includes(type);
  const infinite = ["n"].includes(type);

  return (
    <div className={clsx(styles.container, focused && styles.focused)}>
      <NumericUncontrolledInput
        min={0}
        // @ts-ignore
        value={infinite ? "∞" : value}
        disabled={disabled}
        className={styles.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onValueChange}
      />

      <Menu2 align="start" items={resistanceOptions} onSelect={onTypeChange}>
        <ResistanceButton>
          {resistanceOptions.find((v) => v.id === type)?.label}{" "}
          <i className="ri-arrow-drop-down-line"></i>
        </ResistanceButton>
      </Menu2>
    </div>
  );
};
