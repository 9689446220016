import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
};

export type AuthenticateInput = {
  token: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  cycles: Array<Cycle>;
  group?: Maybe<Group>;
  groupId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  trainerId: Scalars['ID'];
  user: User;
  userId: Scalars['ID'];
};

export type CreateCycleInput = {
  clientId?: InputMaybe<Scalars['ID']>;
  programId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  photo?: InputMaybe<Scalars['String']>;
};

export type CreateProgramInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CreateSessionInput = {
  cycleId?: InputMaybe<Scalars['ID']>;
  programId?: InputMaybe<Scalars['ID']>;
  programRef?: InputMaybe<Scalars['String']>;
  trainerId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type Cycle = {
  __typename?: 'Cycle';
  archivedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  program?: Maybe<Program>;
  programId?: Maybe<Scalars['ID']>;
  trainerId?: Maybe<Scalars['ID']>;
  user: User;
  userId: Scalars['ID'];
};

export type Group = {
  __typename?: 'Group';
  description: Scalars['String'];
  id: Scalars['ID'];
  invites: Array<Invite>;
  members: Array<Membership>;
  name: Scalars['String'];
  photo: Scalars['String'];
};

export type Invite = {
  __typename?: 'Invite';
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type JoinGroupResponse = {
  __typename?: 'JoinGroupResponse';
  invite?: Maybe<Invite>;
  member?: Maybe<Membership>;
};

export type MagicLinkInput = {
  email: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  group: Group;
  groupId: Scalars['ID'];
  id: Scalars['ID'];
  role: Scalars['String'];
  user: User;
  userId: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate: Scalars['Boolean'];
  createCycle: Cycle;
  createGroup: Group;
  createMagicLink: Scalars['Boolean'];
  createProgram: Program;
  createSession: Session;
  joinGroup: JoinGroupResponse;
  leaveGroup: Scalars['Boolean'];
  removeMembership: Scalars['Boolean'];
  updateClient: Client;
  updateGroup: Group;
  updatePlan: Program;
  updateProgram: Program;
  updateSelf: User;
  updateSession: Session;
};


export type MutationAuthenticateArgs = {
  input: AuthenticateInput;
};


export type MutationCreateCycleArgs = {
  input: CreateCycleInput;
};


export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


export type MutationCreateMagicLinkArgs = {
  input: MagicLinkInput;
};


export type MutationCreateProgramArgs = {
  input: CreateProgramInput;
};


export type MutationCreateSessionArgs = {
  input: CreateSessionInput;
};


export type MutationJoinGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationLeaveGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationRemoveMembershipArgs = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateClientArgs = {
  clientId: Scalars['ID'];
  input: UpdateClientInput;
};


export type MutationUpdateGroupArgs = {
  id: Scalars['ID'];
  input: UpdateGroupInput;
};


export type MutationUpdatePlanArgs = {
  id: Scalars['ID'];
  input: UpdatePlanInput;
};


export type MutationUpdateProgramArgs = {
  id: Scalars['ID'];
  input: UpdateProgramInput;
};


export type MutationUpdateSelfArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateSessionArgs = {
  id: Scalars['ID'];
  input: UpdateSessionInput;
};

export type Program = {
  __typename?: 'Program';
  archivedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  muscleGroups: Array<Scalars['String']>;
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['ID'];
  plan: Scalars['JSON'];
  totalKilograms: Scalars['Int'];
  totalSets: Scalars['Int'];
  totalUsers: Scalars['Int'];
  trainingDays: Scalars['Int'];
  trainingWeeks: Scalars['Int'];
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
  visibility?: Maybe<Visibility>;
};

export type Query = {
  __typename?: 'Query';
  client: Client;
  clients: Array<Client>;
  group: Group;
  invites: Array<Invite>;
  memberships: Array<Membership>;
  program?: Maybe<Program>;
  programs: Array<Program>;
  self: User;
  session: Session;
  sessions: Array<Session>;
};


export type QueryClientArgs = {
  clientId: Scalars['ID'];
};


export type QueryClientsArgs = {
  groupId?: InputMaybe<Scalars['ID']>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryProgramArgs = {
  id: Scalars['ID'];
};


export type QuerySessionArgs = {
  id: Scalars['ID'];
};

export type Session = {
  __typename?: 'Session';
  createdAt?: Maybe<Scalars['Date']>;
  cycleId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  log?: Maybe<Scalars['JSON']>;
  programId?: Maybe<Scalars['String']>;
  trainerId?: Maybe<Scalars['ID']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateClientInput = {
  groupId?: InputMaybe<Scalars['ID']>;
};

export type UpdateGroupInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
};

export type UpdatePlanInput = {
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['JSON']>;
  trainingDays?: InputMaybe<Scalars['Int']>;
  trainingWeeks?: InputMaybe<Scalars['Int']>;
};

export type UpdateProgramInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSessionInput = {
  log?: InputMaybe<Scalars['JSON']>;
};

export type UpdateUserInput = {
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  photo: Scalars['String'];
  uid: Scalars['ID'];
};

export enum Visibility {
  Hidden = 'hidden',
  Visible = 'visible'
}

export type AuthenticateMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type AuthenticateMutation = { __typename?: 'Mutation', authenticate: boolean };

export type CreateCycleMutationVariables = Exact<{
  input: CreateCycleInput;
}>;


export type CreateCycleMutation = { __typename?: 'Mutation', createCycle: { __typename?: 'Cycle', id: string } };

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'Group', id: string, name: string, photo: string } };

export type CreateProgramMutationVariables = Exact<{
  input: CreateProgramInput;
}>;


export type CreateProgramMutation = { __typename?: 'Mutation', createProgram: { __typename?: 'Program', id: string, name: string, visibility?: Visibility | null } };

export type CreateSessionMutationVariables = Exact<{
  input: CreateSessionInput;
}>;


export type CreateSessionMutation = { __typename?: 'Mutation', createSession: { __typename?: 'Session', id: string, type: string, userId?: string | null, trainerId?: string | null, programId?: string | null, cycleId?: string | null, log?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type GetClientQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClientQuery = { __typename?: 'Query', client: { __typename?: 'Client', id: string, trainerId: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string }, cycles: Array<{ __typename?: 'Cycle', id: string, trainerId?: string | null, createdAt: any, archivedAt?: any | null, program?: { __typename?: 'Program', id: string, name: string } | null }> } };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', id: string, trainerId: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> };

export type GetGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGroupQuery = { __typename?: 'Query', group: { __typename?: 'Group', id: string, name: string, photo: string, members: Array<{ __typename?: 'Membership', id: string, role: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> } };

export type GetGroupPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGroupPageQuery = { __typename?: 'Query', group: { __typename?: 'Group', id: string, name: string, photo: string, members: Array<{ __typename?: 'Membership', id: string, role: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> }, clients: Array<{ __typename?: 'Client', id: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> };

export type GetGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGroupsQuery = { __typename?: 'Query', memberships: Array<{ __typename?: 'Membership', id: string, role: string, group: { __typename?: 'Group', id: string, name: string, photo: string, members: Array<{ __typename?: 'Membership', id: string, role: string, user: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> } }> };

export type GetProgramQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProgramQuery = { __typename?: 'Query', program?: { __typename?: 'Program', id: string, name: string, plan: any, trainingDays: number, trainingWeeks: number } | null };

export type GetProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProgramsQuery = { __typename?: 'Query', programs: Array<{ __typename?: 'Program', id: string, name: string, totalUsers: number, totalKilograms: number, totalSets: number, trainingWeeks: number, trainingDays: number, ownerId: string, owner: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } }> };

export type GetSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfQuery = { __typename?: 'Query', self: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } };

export type GetSessionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetSessionQuery = { __typename?: 'Query', session: { __typename?: 'Session', id: string, log?: any | null } };

export type GetSessionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSessionsQuery = { __typename?: 'Query', sessions: Array<{ __typename?: 'Session', id: string, type: string, userId?: string | null, trainerId?: string | null, programId?: string | null, cycleId?: string | null, log?: any | null, createdAt?: any | null, updatedAt?: any | null }> };

export type UpdateClientMutationVariables = Exact<{
  clientId: Scalars['ID'];
  input: UpdateClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'Client', id: string, groupId?: string | null } };

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdatePlanInput;
}>;


export type UpdatePlanMutation = { __typename?: 'Mutation', updatePlan: { __typename?: 'Program', id: string, trainingDays: number, trainingWeeks: number, plan: any } };

export type UpdateSelfMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateSelfMutation = { __typename?: 'Mutation', updateSelf: { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string } };

export type UpdateSessionMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSessionInput;
}>;


export type UpdateSessionMutation = { __typename?: 'Mutation', updateSession: { __typename?: 'Session', id: string, type: string, userId?: string | null, trainerId?: string | null, programId?: string | null, cycleId?: string | null, log?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type EditorProgramFragment = { __typename?: 'Program', id: string, name: string, plan: any, trainingDays: number, trainingWeeks: number };

export type UserFragment = { __typename?: 'User', id: string, uid: string, email: string, photo: string, name: string };

export const EditorProgramFragmentDoc = gql`
    fragment EditorProgram on Program {
  id
  name
  plan
  trainingDays
  trainingWeeks
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  uid
  email
  photo
  name
}
    `;
export const AuthenticateDocument = gql`
    mutation Authenticate($token: String!) {
  authenticate(input: {token: $token})
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const CreateCycleDocument = gql`
    mutation CreateCycle($input: CreateCycleInput!) {
  createCycle(input: $input) {
    id
  }
}
    `;
export type CreateCycleMutationFn = Apollo.MutationFunction<CreateCycleMutation, CreateCycleMutationVariables>;

/**
 * __useCreateCycleMutation__
 *
 * To run a mutation, you first call `useCreateCycleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCycleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCycleMutation, { data, loading, error }] = useCreateCycleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCycleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCycleMutation, CreateCycleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCycleMutation, CreateCycleMutationVariables>(CreateCycleDocument, options);
      }
export type CreateCycleMutationHookResult = ReturnType<typeof useCreateCycleMutation>;
export type CreateCycleMutationResult = Apollo.MutationResult<CreateCycleMutation>;
export type CreateCycleMutationOptions = Apollo.BaseMutationOptions<CreateCycleMutation, CreateCycleMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($input: CreateGroupInput!) {
  createGroup(input: $input) {
    id
    name
    photo
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateProgramDocument = gql`
    mutation CreateProgram($input: CreateProgramInput!) {
  createProgram(input: $input) {
    id
    name
    visibility
  }
}
    `;
export type CreateProgramMutationFn = Apollo.MutationFunction<CreateProgramMutation, CreateProgramMutationVariables>;

/**
 * __useCreateProgramMutation__
 *
 * To run a mutation, you first call `useCreateProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProgramMutation, { data, loading, error }] = useCreateProgramMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProgramMutation(baseOptions?: Apollo.MutationHookOptions<CreateProgramMutation, CreateProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProgramMutation, CreateProgramMutationVariables>(CreateProgramDocument, options);
      }
export type CreateProgramMutationHookResult = ReturnType<typeof useCreateProgramMutation>;
export type CreateProgramMutationResult = Apollo.MutationResult<CreateProgramMutation>;
export type CreateProgramMutationOptions = Apollo.BaseMutationOptions<CreateProgramMutation, CreateProgramMutationVariables>;
export const CreateSessionDocument = gql`
    mutation CreateSession($input: CreateSessionInput!) {
  createSession(input: $input) {
    id
    type
    userId
    trainerId
    programId
    cycleId
    log
    createdAt
    updatedAt
  }
}
    `;
export type CreateSessionMutationFn = Apollo.MutationFunction<CreateSessionMutation, CreateSessionMutationVariables>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSessionMutation, CreateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSessionMutation, CreateSessionMutationVariables>(CreateSessionDocument, options);
      }
export type CreateSessionMutationHookResult = ReturnType<typeof useCreateSessionMutation>;
export type CreateSessionMutationResult = Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<CreateSessionMutation, CreateSessionMutationVariables>;
export const GetClientDocument = gql`
    query GetClient($id: ID!) {
  client(clientId: $id) {
    id
    trainerId
    user {
      ...User
    }
    cycles {
      id
      trainerId
      program {
        id
        name
      }
      createdAt
      archivedAt
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClientQuery(baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const GetClientsDocument = gql`
    query GetClients {
  clients {
    id
    trainerId
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetGroupDocument = gql`
    query GetGroup($id: ID!) {
  group(id: $id) {
    id
    name
    photo
    members {
      id
      role
      user {
        ...User
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupQuery(baseOptions: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
      }
export function useGetGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupQuery, GetGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(GetGroupDocument, options);
        }
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<typeof useGetGroupLazyQuery>;
export type GetGroupQueryResult = Apollo.QueryResult<GetGroupQuery, GetGroupQueryVariables>;
export const GetGroupPageDocument = gql`
    query GetGroupPage($id: ID!) {
  group(id: $id) {
    id
    name
    photo
    members {
      id
      role
      user {
        ...User
      }
    }
  }
  clients(groupId: $id) {
    id
    user {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetGroupPageQuery__
 *
 * To run a query within a React component, call `useGetGroupPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGroupPageQuery(baseOptions: Apollo.QueryHookOptions<GetGroupPageQuery, GetGroupPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupPageQuery, GetGroupPageQueryVariables>(GetGroupPageDocument, options);
      }
export function useGetGroupPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupPageQuery, GetGroupPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupPageQuery, GetGroupPageQueryVariables>(GetGroupPageDocument, options);
        }
export type GetGroupPageQueryHookResult = ReturnType<typeof useGetGroupPageQuery>;
export type GetGroupPageLazyQueryHookResult = ReturnType<typeof useGetGroupPageLazyQuery>;
export type GetGroupPageQueryResult = Apollo.QueryResult<GetGroupPageQuery, GetGroupPageQueryVariables>;
export const GetGroupsDocument = gql`
    query GetGroups {
  memberships {
    id
    role
    group {
      id
      name
      photo
      members {
        id
        role
        user {
          ...User
        }
      }
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetGroupsQuery__
 *
 * To run a query within a React component, call `useGetGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
      }
export function useGetGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupsQuery, GetGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupsQuery, GetGroupsQueryVariables>(GetGroupsDocument, options);
        }
export type GetGroupsQueryHookResult = ReturnType<typeof useGetGroupsQuery>;
export type GetGroupsLazyQueryHookResult = ReturnType<typeof useGetGroupsLazyQuery>;
export type GetGroupsQueryResult = Apollo.QueryResult<GetGroupsQuery, GetGroupsQueryVariables>;
export const GetProgramDocument = gql`
    query GetProgram($id: ID!) {
  program(id: $id) {
    ...EditorProgram
  }
}
    ${EditorProgramFragmentDoc}`;

/**
 * __useGetProgramQuery__
 *
 * To run a query within a React component, call `useGetProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProgramQuery(baseOptions: Apollo.QueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, options);
      }
export function useGetProgramLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProgramQuery, GetProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProgramQuery, GetProgramQueryVariables>(GetProgramDocument, options);
        }
export type GetProgramQueryHookResult = ReturnType<typeof useGetProgramQuery>;
export type GetProgramLazyQueryHookResult = ReturnType<typeof useGetProgramLazyQuery>;
export type GetProgramQueryResult = Apollo.QueryResult<GetProgramQuery, GetProgramQueryVariables>;
export const GetProgramsDocument = gql`
    query GetPrograms {
  programs {
    id
    name
    totalUsers
    totalKilograms
    totalSets
    trainingWeeks
    trainingDays
    ownerId
    owner {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetProgramsQuery__
 *
 * To run a query within a React component, call `useGetProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProgramsQuery, GetProgramsQueryVariables>(GetProgramsDocument, options);
      }
export function useGetProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProgramsQuery, GetProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProgramsQuery, GetProgramsQueryVariables>(GetProgramsDocument, options);
        }
export type GetProgramsQueryHookResult = ReturnType<typeof useGetProgramsQuery>;
export type GetProgramsLazyQueryHookResult = ReturnType<typeof useGetProgramsLazyQuery>;
export type GetProgramsQueryResult = Apollo.QueryResult<GetProgramsQuery, GetProgramsQueryVariables>;
export const GetSelfDocument = gql`
    query GetSelf {
  self {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
      }
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export const GetSessionDocument = gql`
    query GetSession($id: ID!) {
  session(id: $id) {
    id
    log
  }
}
    `;

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
      }
export function useGetSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options);
        }
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>;
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>;
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>;
export const GetSessionsDocument = gql`
    query GetSessions {
  sessions {
    id
    type
    userId
    trainerId
    programId
    cycleId
    log
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetSessionsQuery__
 *
 * To run a query within a React component, call `useGetSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
      }
export function useGetSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSessionsQuery, GetSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSessionsQuery, GetSessionsQueryVariables>(GetSessionsDocument, options);
        }
export type GetSessionsQueryHookResult = ReturnType<typeof useGetSessionsQuery>;
export type GetSessionsLazyQueryHookResult = ReturnType<typeof useGetSessionsLazyQuery>;
export type GetSessionsQueryResult = Apollo.QueryResult<GetSessionsQuery, GetSessionsQueryVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($clientId: ID!, $input: UpdateClientInput!) {
  updateClient(clientId: $clientId, input: $input) {
    id
    groupId
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdatePlanDocument = gql`
    mutation UpdatePlan($id: ID!, $input: UpdatePlanInput!) {
  updatePlan(id: $id, input: $input) {
    id
    trainingDays
    trainingWeeks
    plan
  }
}
    `;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
      }
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const UpdateSelfDocument = gql`
    mutation UpdateSelf($input: UpdateUserInput!) {
  updateSelf(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateSelfMutationFn = Apollo.MutationFunction<UpdateSelfMutation, UpdateSelfMutationVariables>;

/**
 * __useUpdateSelfMutation__
 *
 * To run a mutation, you first call `useUpdateSelfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSelfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSelfMutation, { data, loading, error }] = useUpdateSelfMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSelfMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSelfMutation, UpdateSelfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSelfMutation, UpdateSelfMutationVariables>(UpdateSelfDocument, options);
      }
export type UpdateSelfMutationHookResult = ReturnType<typeof useUpdateSelfMutation>;
export type UpdateSelfMutationResult = Apollo.MutationResult<UpdateSelfMutation>;
export type UpdateSelfMutationOptions = Apollo.BaseMutationOptions<UpdateSelfMutation, UpdateSelfMutationVariables>;
export const UpdateSessionDocument = gql`
    mutation UpdateSession($id: ID!, $input: UpdateSessionInput!) {
  updateSession(id: $id, input: $input) {
    id
    type
    userId
    trainerId
    programId
    cycleId
    log
    createdAt
    updatedAt
  }
}
    `;
export type UpdateSessionMutationFn = Apollo.MutationFunction<UpdateSessionMutation, UpdateSessionMutationVariables>;

/**
 * __useUpdateSessionMutation__
 *
 * To run a mutation, you first call `useUpdateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionMutation, { data, loading, error }] = useUpdateSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSessionMutation, UpdateSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSessionMutation, UpdateSessionMutationVariables>(UpdateSessionDocument, options);
      }
export type UpdateSessionMutationHookResult = ReturnType<typeof useUpdateSessionMutation>;
export type UpdateSessionMutationResult = Apollo.MutationResult<UpdateSessionMutation>;
export type UpdateSessionMutationOptions = Apollo.BaseMutationOptions<UpdateSessionMutation, UpdateSessionMutationVariables>;