import {
  ApolloClient,
  defaultDataIdFromObject,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { getMainDefinition } from "@apollo/client/utilities";
import { authLink } from "./auth";
import { errors } from "./errors";
import { httpLink } from "./http";
import { wsLink } from "./ws";

const retry = new RetryLink({
  attempts: {
    max: 3,
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);

    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  from([retry, authLink, httpLink])
);

export const links = from([errors, splitLink]);

export const apolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case "User":
          return `Product:${responseObject.uid}`;

        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }),
});
