// import { VerticalDrawer } from "components/VerticalDrawer/VerticalDrawer";
import { AppPage } from "app/AppPage/AppPage"
import { useAuthenticatedWrapper } from "app/AuthenticatedWrapper"
import { PageSection } from "app/PageSection"
import { GridButtonGroup } from "components/GridButtonGroup/GridButtonGroup"
import { createTeam, useTeams } from "db/Team.db"
import { useState } from "react"
// import { NumberInput } from "workout_modals/NumberInput";
// import { InputButton } from "components/Input_Numeric_WithDropdown/InputButton";
import { InputStepper } from "components/Inputs/InputStepper/InputStepper"
import { InputNumber } from "components/Input_Numeric_WithDropdown/InputNumber"
import { InputNumber as InputN2 } from "components/Inputs/InputNumber_WithDropdown/InputNumber"
import { ParametersSheet } from "./ParametersSheet"
import { SearchSheet } from "./SearchLayer/SearchSheet"
import { useCreateProgramMutation, useGetProgramsQuery } from "graphql/types"
import { nanoid } from "nanoid"

export const Dashboard = () => {
  const { user } = useAuthenticatedWrapper()

  const teams = useTeams(user)

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)

  const [number, setNumber] = useState(0)
  const [string, setString] = useState("")
  const [option, setOption] = useState("one")

  const [createProgramMutation] = useCreateProgramMutation()

  const programsQuery = useGetProgramsQuery()

  console.log(programsQuery)

  if (!user || teams.loading) {
    return null
  }

  return (
    <AppPage>
      <button
        className="button button--secondary button--small"
        onClick={() => {
          setOpen(true)
        }}
      >
        Open
      </button>

      <button
        className="button button--secondary button--small"
        onClick={() => {
          setOpen2(true)
        }}
      >
        Open 2
      </button>
      <button
        onClick={async () => {
          const program = await createProgramMutation({
            variables: {
              input: {
                name: nanoid()
              }
            }
          })

          console.log(program)
        }}
      >
        Create program
      </button>
      {/* 
      <VerticalDrawer open={open} onOpen={setOpen}>
        <NumberInput
          label="Resistance"
          step={1}
          initialValue={0}
          onUpdate={() => {}}
        />

        <NumberInput
          label="Repetitions"
          step={1}
          initialValue={0}
          onUpdate={() => {}}
        />

        <button className="button ui--wide button--large" onClick={() => {}}>
          Save
        </button>
      </VerticalDrawer> */}

      <PageSection label="Team">
        <GridButtonGroup
          buttons={[
            {
              title: "Today",
              icon: "ri-add-line",
              description: "0"
            },
            {
              title: "Schedules",
              icon: "ri-home-3-fill",
              color: "#fe9503",
              description: "2"
            },
            {
              title: "All",
              icon: "ri-sparkling-2-fill",
              description: "0"
            },
            {
              title: "Flagged",
              icon: "ri-flag-fill",
              description: "12"
            }
          ]}
        ></GridButtonGroup>
        {teams.value?.map((team, index) => {
          return (
            <div key={index}>
              {JSON.stringify(team)}

              <div>
                <button className="button button--secondary">Invite</button>
              </div>
            </div>
          )
        })}

        <div>
          <button
            className="button button--secondary"
            onClick={() => {
              if (!user) return

              createTeam(
                {
                  name: "Third Space",
                  photoUrl: ""
                },
                user
              )
            }}
          >
            Create team
          </button>
        </div>
      </PageSection>

      <PageSection label="Input types">
        <div className="row" style={{ flexWrap: "wrap", gap: "0.5rem" }}>
          <InputNumber
            input={number}
            option={option}
            inputDisabled={(_, option) => option === "two"}
            options={[
              {
                label: "Option 1",
                value: "one"
              },
              {
                label: "Option 2",
                value: "two"
              }
            ]}
            onInputChange={setNumber}
            onOptionChange={setOption}
          />

          <InputN2
            input={number}
            option={option}
            inputDisabled={(_, option) => option === "two"}
            options={[
              {
                label: "Option 1",
                value: "one"
              },
              {
                label: "Option 2",
                value: "two"
              }
            ]}
            onInputChange={setNumber}
            onOptionChange={setOption}
          />

          <InputStepper
            min={-10}
            max={100}
            value={number}
            onChange={setNumber}
          />

          <InputStepper
            size="medium"
            min={-10}
            max={100}
            value={number}
            onChange={setNumber}
          />
        </div>
      </PageSection>
      <PageSection label="Weight">
        <InputStepper
          size="medium"
          min={-10}
          max={100}
          value={number}
          onChange={setNumber}
        />
      </PageSection>

      <PageSection label="Weight">
        <div className="ui-stack">
          <InputStepper
            fill
            size="medium"
            min={-10}
            max={100}
            value={number}
            onChange={setNumber}
          />
        </div>
      </PageSection>

      <PageSection label="Weight">
        <div
          className="ui-stack"
          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        >
          <InputStepper
            fill
            size="medium"
            min={-10}
            max={100}
            value={number}
            onChange={setNumber}
          />
        </div>
      </PageSection>
      <ParametersSheet open={open} onOpen={setOpen} onUpdate={() => {}} />
      <SearchSheet open={open2} onOpen={setOpen2} onSelect={() => {}} />

      {/* <VerticalDrawer open={open} onOpen={setOpen}>
        <div style={{ padding: "0 2rem", paddingBottom: "1rem" }}>
          <PageSection label="Weight" align="center">
            <div className="ui-stack">
              <InputStepper
                fill
                size="medium"
                min={-10}
                max={100}
                value={number}
                onChange={setNumber}
              />
            </div>
          </PageSection>

          <PageSection label="Reps" align="center">
            <div className="ui-stack">
              <InputStepper
                fill
                size="medium"
                min={-10}
                max={100}
                value={number}
                onChange={setNumber}
              />
            </div>
          </PageSection>
        </div>

        <div
          style={{
            background: "#dff8eb",
            color: "#33b06f",
            padding: "1.5rem 2rem",

            width: "100%",
            textAlign: "center",
            borderTop: "1px solid #d1f1df",
          }}
        >
          Save changes
        </div>
      </VerticalDrawer> */}
    </AppPage>
  )
}
