import { useState } from "react";
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper";
// import { usePrograms } from "../../db/Program.db";
import { useCreateWorkout } from "../../db/Workout.db";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageSection } from "../../app/PageSection";
import { SettingsRow } from "../Program/SettingsRow";
import { AppPage } from "../../app/AppPage/AppPage";

export const Start = () => {
  const [search] = useSearchParams();

  const { user } = useAuthenticatedWrapper();
  // const programs = usePrograms(user);
  const navigate = useNavigate();

  const [programId, setProgramId] = useState(search.get("i_program") || "");
  const [name, setName] = useState("");

  const createWorkout = useCreateWorkout(user);

  return (
    <AppPage backHref="/">
      <PageSection label="New workout">
        <select
          className="select"
          value={programId}
          onChange={(event) => {
            const id = event.target.value;
            setProgramId(id);

            if (id) {
              // const program = programs.value!.find((v) => v.id === id);
              // if (program) {
              //   setName(program.name);
              // }
            }
          }}
        >
          <option value="">Select a plan</option>

          {/* {programs.value?.map((program) => (
            <option key={program.id} value={program.id}>
              {program.name}
            </option>
          ))} */}
        </select>

        {programId && (
          <div>
            <SettingsRow label="Name" value={name} onChange={setName} />

            <button
              className="button"
              onClick={async () => {
                await createWorkout(programId, name);
                navigate("/");
              }}
            >
              Create new workout
            </button>
          </div>
        )}
      </PageSection>
    </AppPage>
  );
};
