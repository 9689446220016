import { PropsWithChildren, forwardRef } from "react";
import clsx from "clsx";

import styles from "./InputContainer.module.scss";

export interface InputContainerProps extends PropsWithChildren {
  focused?: boolean;
  fill?: boolean;
}

export const InputContainer = forwardRef<HTMLDivElement, InputContainerProps>(
  ({ children, focused = false, fill = false, ...rest }, ref) => (
    <div
      className={clsx(
        styles.container,
        focused && styles.focused,
        fill && styles.fill
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  )
);
