import { useState } from "react";
import { InputButton } from "./InputButton";
import { Menu2 } from "components/Menu2";
import styles from "./InputNumber.module.scss";
import clsx from "clsx";
import { NumericUncontrolledInput } from "components/NumericUncontrolledInput";

export interface Option {
  value: string;
  label: string;
}

export interface InputNumberProps {
  input: number;
  size?: "small" | "medium";
  inputDisabled?: (input: number, option: string) => boolean;
  option: string;
  options: Option[];
  onInputChange: (input: number) => void;
  onOptionChange: (option: string) => void;
}

export const InputNumber = ({
  size = "small",
  input,
  inputDisabled,
  option,
  options,
  onInputChange,
  onOptionChange,
}: InputNumberProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={clsx(styles.container, focused && styles.focused)}>
      <NumericUncontrolledInput
        min={0}
        value={input}
        disabled={inputDisabled?.(input, option)}
        className={styles.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={onInputChange}
      />

      <Menu2
        align="start"
        items={options.map((option) => {
          return {
            label: option.label,
            id: option.value,
          };
        })}
        onSelect={(id) => {
          onOptionChange(id);
        }}
      >
        <InputButton>
          {options.find((v) => v.value === option)?.label}{" "}
          <i className="ri-arrow-drop-down-line"></i>
        </InputButton>
      </Menu2>
    </div>
  );
};
