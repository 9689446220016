import clsx from "clsx";
import { IProgram, SetStatus } from "../../../lib/types";
import { repeat } from "../../../utils";
import styles from "./DaySelector.module.scss";
import { IWorkoutView } from "../../../lib/_workout";

export interface DaySelectorProps {
  program: IProgram;
  view: IWorkoutView;
  selectedWeekIndex: number;
  selectedDayIndex: number;
  onSelect: (weekIndex: number, dayIndex: number) => void;
}

export const DaySelector = ({
  program,
  view,
  onSelect,
  selectedDayIndex,
  selectedWeekIndex,
}: DaySelectorProps) => {
  const month = repeat(program.weeks).map((_, weekIndex) => {
    return repeat(program.days).map((_, dayIndex) => {
      const key = weekIndex + "-" + dayIndex;
      const exercises = view[key] ?? [];

      const done =
        exercises.length > 0 &&
        exercises.every((exercise) =>
          exercise.sets.every((set) => set.status === SetStatus.Done)
        );

      return {
        done,
      };
    });
  });

  return (
    <div className={styles.component}>
      <div className={styles.weeks}>
        {month.map((week, weekIndex) => (
          <div key={weekIndex} className={styles.week}>
            <div className={styles.weekTitle}>Week {weekIndex + 1}</div>

            <div className={styles.days}>
              {week.map((day, dayIndex) => (
                <div
                  key={dayIndex}
                  className={clsx(
                    styles.day,
                    day.done && styles.done,
                    weekIndex === selectedWeekIndex &&
                      dayIndex === selectedDayIndex &&
                      styles.selectedDay
                  )}
                  onClick={() => {
                    onSelect(weekIndex, dayIndex);
                  }}
                >
                  {dayIndex + 1}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
