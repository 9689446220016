import { PageSection } from "../../app/PageSection"
import { useNavigate } from "react-router-dom"
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper"
import { AppPage } from "../../app/AppPage/AppPage"
import {
  useCreateProgramMutation,
  useGetProgramsQuery,
  useGetSessionsQuery
} from "graphql/types"
import clsx from "clsx"
import { formatDate } from "./formatDate"
import styles from "./Home.module.scss"
import { useState } from "react"
import { SessionLayer } from "components/Layers/Layer_Session/Session"
import { parseISO } from "date-fns"
import { SetStatus } from "lib/types"
import { ISessionExercise } from "routes/Session/schemas"

const useSessions = () => {
  const { data, loading } = useGetSessionsQuery()

  return data?.sessions ?? []
}

export const Home = () => {
  const navigate = useNavigate()

  const { data, loading, error } = useGetProgramsQuery({
    fetchPolicy: "cache-and-network",
    skip: false
  })

  const programs = data?.programs ?? []

  const sessions = useSessions()

  const [createProgramMutation] = useCreateProgramMutation()

  const [isCreatingSession, setCreatingSession] = useState(false)

  return (
    <AppPage>
      <PageSection label="Sessions">
        <div className={clsx(styles.sessions)}>
          <div
            className={clsx(styles.session)}
            onClick={() => {
              setCreatingSession(true)
            }}
          >
            <div className={styles.row}>
              <div className={styles.title}>Start a new session</div>
              <div>
                <i className="ri-arrow-right-s-line"></i>
              </div>
            </div>
          </div>

          <div style={{ height: 12 }} />
        </div>

        <div className={styles.historyList}>
          {sessions.map((session, index) => (
            <div
              key={session.id}
              className={clsx(
                styles.historyItem,
                index === 0 && styles.historyItemFirst,
                sessions.length - 1 === index && styles.historyItemLast
              )}
              onClick={() => {
                navigate(`/sessions/${session.id}`)
              }}
            >
              <div className={styles.row}>
                <div>
                  <div className={styles.title}>
                    {formatDate(parseISO(session.createdAt))} workout
                  </div>
                  <div className={styles.continue}>Continue this workout</div>
                </div>

                {(session.log.exercises as ISessionExercise[]).every(
                  (exercise) =>
                    exercise.sets.every((v) => v.status === SetStatus.Done)
                ) ? (
                  <div
                    className={clsx(styles.continue)}
                    style={{ color: "#4dab5b" }}
                  >
                    <i className="ri-check-line" /> Finished
                  </div>
                ) : (
                  <div>
                    <i className="ri-arrow-right-s-line"></i>
                  </div>
                )}
              </div>
            </div>
          ))}
          {/* <div className={clsx(styles.historyItem, styles.historyItemFirst)}>
            <div className={styles.row}>
              <div>
                <div className={styles.title}>{formatDate(date)} workout</div>
                <div className={styles.continue}>Continue this workout</div>
              </div>

              <div>
                <i className="ri-arrow-right-s-line"></i>
              </div>
            </div>
          </div>

          <div className={clsx(styles.historyItem)}>
            <div className={styles.row}>
              <div>
                <div className={styles.title}>{formatDate(date)} workout</div>
                <div className={styles.continue}>Continue this workout</div>
              </div>

              <div>
                <i className="ri-arrow-right-s-line"></i>
              </div>
            </div>
          </div>

          <div className={clsx(styles.historyItem, styles.historyItemLast)}>
            <div className={styles.row}>
              <div>
                <div className={styles.title}>{formatDate(date)} workout</div>
                <div className={styles.continue}>Chest press, abs</div>
              </div>

              <div
                className={clsx(styles.continue)}
                style={{ color: "#4dab5b" }}
              >
                <i className="ri-check-line" /> Finished
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className={styles.more}>Show all history</div> */}
      </PageSection>

      <div style={{ height: "0.5rem" }} />

      <PageSection
        label="Programs"
        more={
          <div className="ui-row">
            <button
              className="button button--secondary button--small"
              onClick={async () => {
                const response = await createProgramMutation({
                  variables: {
                    input: {
                      name: "New program"
                    }
                  }
                })

                const id = response.data?.createProgram.id

                if (id) {
                  navigate(`/programs/${id}`)
                }
              }}
            >
              New program
            </button>
          </div>
        }
      >
        <div className={styles.programs}>
          {programs.map((program) => (
            <div
              key={program.id}
              className={styles.program}
              onClick={() => {
                navigate(`/programs/${program.id}`)
              }}
            >
              <div className={styles.about}>
                <div>{program.name}</div>
                {/* <div className={styles.arrow}>
                  <i className="ri-arrow-right-up-line"></i>
                </div> */}
                <div className={styles.name}>by {program.owner.name}</div>
              </div>

              <div className={styles.tabs}>
                <div className={styles.tab}>
                  <div className={styles.stat}>
                    <div>{program.trainingWeeks}</div>
                    <div>weeks</div>
                  </div>
                </div>
                <div className={styles.tab}>
                  <div className={styles.stat}>
                    <div>{program.trainingDays}</div>
                    <div>sessions</div>
                  </div>
                </div>
                <div className={styles.tab}>
                  <div className={styles.stat}>
                    <div>{program.totalUsers}</div>
                    <div>users</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={clsx(styles.more, styles.disabled)}>
          Show more programs
        </div>
      </PageSection>

      <SessionLayer open={isCreatingSession} onOpen={setCreatingSession} />
    </AppPage>
  )
}
