import { Sheet } from "components/Layer/Sheet";
import { storage } from "lib/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useState } from "react";
import { SheetContent } from "components/Layer/SheetContent";
import { SheetButton } from "components/Layer/SheetButton";
import { Avatar } from "components/Avatar/Avatar";
import clsx from "clsx";
import {
  useCreateSessionMutation,
  useGetSelfQuery,
  useUpdateSelfMutation,
} from "graphql/types";

import styles from "./Session.module.scss";
import { useAuthenticatedWrapper } from "app/AuthenticatedWrapper";

export interface SessionLayerProps {
  open: boolean;
  onOpen: (v: boolean) => void;
}

export const SessionLayer = ({ open, onOpen }: SessionLayerProps) => {
  // cycleId?: InputMaybe<Scalars['ID']>;
  // programId?: InputMaybe<Scalars['ID']>;
  // programRef?: InputMaybe<Scalars['String']>;
  // trainerId?: InputMaybe<Scalars['String']>;
  // type?: InputMaybe<Scalars['String']>;
  // userId: Scalars['ID'];

  const [createSessionMutation] = useCreateSessionMutation({
    // variables: {
    // }
  });

  const { user } = useAuthenticatedWrapper();

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        <div className="ui-stack" style={{ gap: "0.5rem" }}>
          <SheetButton use="normal" onClick={async () => {}}>
            <span>
              Record <strong>5-3-1 Lifting Like a Dawg</strong> session
            </span>
          </SheetButton>
          <SheetButton
            use="normal"
            onClick={async () => {
              const response = await createSessionMutation({
                variables: {
                  input: {
                    userId: user.uid,
                    type: "individual",
                  },
                },
              });

              console.log(response);
            }}
          >
            Record one-off session
          </SheetButton>
        </div>
      </SheetContent>
    </Sheet>
  );
};
