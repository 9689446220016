import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "remixicon/fonts/remixicon.css"
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper"
import { IExercise, IProgram } from "../../lib/types"
import { SequenceSelector } from "./NumbersSelector"
import { PageSection } from "../../app/PageSection"
import { SettingsNumberRow } from "./SettingsNumberRow"
import { SettingsRow } from "./SettingsRow"
import { repeat, sequence } from "../../utils"
import { AppPage } from "../../app/AppPage/AppPage"
import { ResistanceButton } from "../../components/Button_Resistance/ResistanceButton"
import { Menu2 } from "../../components/Menu2"
import { useGetProgramQuery, useUpdatePlanMutation } from "graphql/types"
import { ProgramContextProvider, useProgramContext } from "./ProgramContext"
import { ExerciseList } from "./ExerciseList"
import { VariableList } from "./VariableList"

export const Program = () => {
  const { id } = useParams()

  const { data, loading } = useGetProgramQuery({
    variables: {
      id: id!
    },
    skip: !id
  })

  const program = data?.program

  const [updatePlanMutation] = useUpdatePlanMutation()

  if (!program || loading) {
    return null
  }

  return (
    <ProgramContextProvider
      initial={{
        name: program.name,
        weeks: program.trainingWeeks,
        days: program.trainingDays,
        variables: program.plan.variables || [],
        exercises: program.plan.exercises || []
      }}
    >
      <Base
        onUpdate={async (program) => {
          await updatePlanMutation({
            variables: {
              id: id!,
              input: {
                name: program.name,
                trainingDays: program.days,
                trainingWeeks: program.weeks,
                plan: {
                  variables: program.variables,
                  exercises: program.exercises
                }
              }
            }
          })

          console.log("Updated program")
        }}
      />
    </ProgramContextProvider>
  )
}

const count = (exercises: IExercise[], week: number, day: number) => {
  return exercises.filter((v) => v.week === week && v.day === day).length
}

interface BaseProps {
  onUpdate: (data: IProgram) => void
}

function Base({ onUpdate }: BaseProps) {
  const { user } = useAuthenticatedWrapper()

  const [state, controls] = useProgramContext()
  const [selectedWeekId, setSelectedWeekId] = useState(0)
  const [selectedDayId, setSelectedDayId] = useState(0)

  useEffect(() => {
    if (user) {
      onUpdate(state)
    }
  }, [state])

  const exercises = state.exercises
    .map((exercise, index) => {
      return {
        index,
        ...exercise
      }
    })
    .filter((v) => v.week === selectedWeekId && v.day === selectedDayId)

  const generateCloneWeekOptions = (weekIndex: number) => {
    return repeat(state.weeks)
      .map((_, index) => {
        return {
          id: `week-${index}`,
          label: `Paste to week ${index + 1}`
        }
      })
      .filter((_, index) => {
        return index !== weekIndex
      })
  }

  return (
    <AppPage backHref="/">
      <PageSection label="Settings">
        <SettingsRow
          label="Name"
          placeholder="Workout Plan"
          value={state.name}
          onChange={controls.updateName}
        />

        <SettingsNumberRow
          label="Duration (weeks)"
          min={1}
          max={4}
          value={state.weeks}
          onChange={controls.updateWeeks}
        />

        <SettingsNumberRow
          label="Number of training days"
          min={1}
          max={7}
          value={state.days}
          onChange={controls.updateDays}
        />
      </PageSection>

      <PageSection label="Schedule">
        {repeat(state.weeks).map((weekIndex) => (
          <div
            className="row"
            key={weekIndex}
            style={{
              width: "100%",
              marginBottom: "1rem",
              marginTop: "1rem"
            }}
          >
            <Menu2
              align="start"
              items={[
                {
                  id: "clone-week",
                  label: "Copy week",
                  children: generateCloneWeekOptions(weekIndex)
                },
                {
                  id: "clear-week",
                  label: "Clear week"
                }
              ]}
              onSelect={(parentId, childId) => {
                if (parentId === "clone-week") {
                  const [_, target] = childId!.split("-")
                  const targetWeekIndex = Number.parseInt(target)

                  controls.cloneWeek(weekIndex, targetWeekIndex)

                  return
                }

                if ((parentId = "clear-week")) {
                  controls.clearWeek(weekIndex)

                  return
                }
              }}
            >
              <ResistanceButton>
                Week {weekIndex + 1} <i className="ri-arrow-drop-down-line" />
              </ResistanceButton>
            </Menu2>

            <div style={{ flex: 1 }} />

            <SequenceSelector
              options={sequence(1, state.days + 1, "D")}
              badge={(_, dayIndex) =>
                count(state.exercises, weekIndex, dayIndex) || undefined
              }
              value={selectedWeekId === weekIndex ? selectedDayId : -1}
              onSelect={(_, dayIndex) => {
                setSelectedWeekId(weekIndex)
                setSelectedDayId(dayIndex)
              }}
            />
          </div>
        ))}
      </PageSection>

      <VariableList />
      <ExerciseList exercises={exercises} />

      {selectedDayId !== -1 && selectedWeekId !== -1 && (
        <div
          style={{
            padding: "1rem",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <button
            id="create-workout-button"
            className="button"
            onClick={() => {
              controls.createExercise(selectedWeekId, selectedDayId)

              document.querySelector("#page-bottom")?.scrollIntoView({
                block: "center",
                behavior: "smooth"
              })
            }}
          >
            Add exercise
          </button>
        </div>
      )}

      <div id="page-bottom" />
    </AppPage>
  )
}
