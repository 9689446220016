import styles from "./GridButtonGroup.module.scss";

export interface GridButton {
  color?: string;
  icon: string;
  title: string;
  description?: string;
}

export interface GridButtonGroupProps {
  buttons: GridButton[];
}

export const GridButtonGroup = ({ buttons }: GridButtonGroupProps) => {
  return (
    <div className={styles.container}>
      {buttons.map((button, index) => {
        return (
          <button key={index} className={styles.button}>
            <div className={styles.top}>
              <div
                className={styles.icon}
                style={{ backgroundColor: button.color }}
              >
                <i className={button.icon} />
              </div>
              {button.description && (
                <div className={styles.description}>{button.description}</div>
              )}
            </div>

            <div className={styles.title}>{button.title}</div>
          </button>
        );
      })}
    </div>
  );
};
