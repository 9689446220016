import styles from "./NumbersSelector.module.scss"
import $ from "clsx"
import { repeat } from "../../utils"

type Props = {
  min: number
  max: number
  value?: number
  badge?: (value: number, index: number) => string | number | boolean
  prefix?: string
  onSelect: (value: number) => void
}

export const NumbersSelector = ({
  min,
  max,
  prefix,
  badge,
  value: selected,
  onSelect
}: Props) => (
  <div className={styles.numbers}>
    {repeat(max - min + 1).map((index) => {
      const number = min + index

      return (
        <div
          key={index}
          data-badge={badge ? badge(number, index) : undefined}
          className={$(styles.number, selected === number && styles.active)}
          onClick={() => onSelect(number)}
        >
          {prefix}
          {number}
        </div>
      )
    })}
  </div>
)

type SequenceProps = {
  options: string[]
  value: number
  badge?: (
    value: string,
    index: number
  ) => string | number | boolean | undefined
  onSelect: (value: string, index: number) => void
}

export const SequenceSelector = ({
  badge,
  options,
  value: selected,
  onSelect
}: SequenceProps) => (
  <div className={styles.numbers}>
    {options.map((value, index) => (
      <div
        key={index}
        data-badge={badge ? badge(value, index) : undefined}
        className={$(styles.number, selected === index && styles.active)}
        onClick={() => onSelect(value, index)}
      >
        {value}
      </div>
    ))}
  </div>
)
