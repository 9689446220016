import { IReps, IResistance } from "../../lib/types";
import { lib } from "../../lib/_lib";
// import { IWorkoutSet } from "../../lib/_workout";
import { ParametersSheet } from "./ParametersSheet";
import { ISessionSet, IVariable } from "./schemas";

export interface WorkoutEditSetWrapperProps {
  set: ISessionSet;
  variables: IVariable[];
  onEdit: (weight: IResistance, reps: IReps) => void;
  onClose: () => void;
}

export const WorkoutEditSetWrapper = ({
  set,
  variables,
  onEdit,
  onClose,
}: WorkoutEditSetWrapperProps) => {
  const open = !!set;

  if (!open) {
    return null;
  }

  const initialWeight = lib.WorkoutSet.computeNominalWeight(set, variables);
  const initialReps = set.reps.value;

  return (
    <ParametersSheet
      open={open}
      onOpen={(open) => {
        if (!open) {
          onClose();
        }
      }}
      initialWeight={initialWeight}
      initialReps={initialReps}
      onUpdate={(weight, reps) => {
        onEdit(lib.Resistance.nominal(weight), lib.Reps.nominal(reps));
      }}
    />
  );
};
