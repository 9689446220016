import { getExerciseName } from "../../../data/Exercises"
import { IVariable, VariableType } from "../../../lib/types"
import $ from "clsx"
import { InputStepper } from "components/Inputs/InputStepper/InputStepper"

import styles from "./VariableRow.module.scss"
export interface VariableRowProps {
  variable: IVariable
  onValueChange: (value: number) => void
}

export const getVariableTypeLabel = (type: VariableType) => {
  switch (type) {
    case "r":
      return "1 Rep Max"

    default:
      return "Unknown"
  }
}

export const VariableRow = ({ variable, onValueChange }: VariableRowProps) => {
  return (
    <div className={styles.component}>
      <div className={$(styles.title)}>
        {getExerciseName(variable.exercise)}
      </div>
      <div>
        <span
          className={$(
            styles.type,
            variable.type === VariableType.RepetitionMax && styles.green
          )}
        >
          {getVariableTypeLabel(variable.type)}
        </span>
      </div>

      <div style={{ flex: 1 }} />

      <InputStepper
        min={0}
        max={999}
        value={variable.value}
        onChange={onValueChange}
      />
    </div>
  )
}
