import { round } from "lodash";
import {
  IReps,
  ISet,
  IVariable,
  IResistance,
  RepetitionsType,
  ResistanceType,
} from "./types";
import { computeNominalResistance } from "./_logic";
import { nanoid } from "nanoid";

export const Set = {
  createSet(weight: number | IResistance, reps: number | IReps): ISet {
    return {
      id: nanoid(),
      weight:
        typeof weight === "number"
          ? {
              type: ResistanceType.Units,
              value: weight,
            }
          : weight,
      reps:
        typeof reps === "number"
          ? {
              type: RepetitionsType.Units,
              value: reps,
            }
          : reps,
    };
  },

  cloneSet(set: ISet): ISet {
    return {
      id: nanoid(),
      weight: { ...set.weight },
      reps: { ...set.reps },
    };
  },

  getLabel(set: ISet, variables: IVariable[]) {
    const { reps, weight } = set;

    switch (weight.type) {
      case ResistanceType.Bodyweight:
        return `${reps.value} x Bodyweight`;

      case ResistanceType.Units:
        return `${reps.value} x ${round(weight.value)}kg`;

      case ResistanceType.Percentage: {
        const value = computeNominalResistance(set, variables);

        return `${reps.value} x ${value}kg (${weight.value}% 1RM)`;
      }

      // case ResistanceType.NotApplicable:
      default:
        return `${reps.value} reps`;
    }
  },
};
