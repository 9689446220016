import React from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import ReactConfetti from "react-confetti";

const SizedConfetti = React.forwardRef<any, any>((passedProps, ref) => {
  const { width, height } = useWindowSize();
  return (
    <ReactConfetti width={width} height={height} {...passedProps} ref={ref} />
  );
});

export interface PartyProps {
  onComplete?: () => void;
}

export const Party = (props: PartyProps) => {
  return (
    <SizedConfetti
      style={{ pointerEvents: "none" }}
      numberOfPieces={500}
      recycle={false}
      onConfettiComplete={(confetti: any) => {
        confetti.reset();
        props.onComplete?.();
      }}
    />
  );
};
