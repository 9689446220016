import { Avatar } from "components/Avatar/Avatar";
import { Button } from "components/Button/Button";
import { ResistanceButton } from "components/Button_Resistance/ResistanceButton";
import { Menu2 } from "components/Menu2";
import {
  GetClientDocument,
  useCreateCycleMutation,
  useGetClientQuery,
  useGetProgramsQuery,
  useUpdateClientMutation,
} from "graphql/types";
import { Link, useParams } from "react-router-dom";
import { useImmer } from "use-immer";

import styles from "./Client.module.scss";

const usePrograms = () => {
  const { data, loading } = useGetProgramsQuery({
    fetchPolicy: "cache-first",
  });

  const programs = data?.programs ?? [];

  return { programs, loading };
};

export const Client = () => {
  const params = useParams();
  const { id } = params;

  const { programs } = usePrograms();

  const [state, setState] = useImmer({
    programId: "",
    clientId: params.id as string,
  });

  const { data, loading } = useGetClientQuery({
    variables: {
      id: id as string,
    },
    skip: !id,
  });

  const [updateClientMutation] = useUpdateClientMutation();
  const [createCycleMutation] = useCreateCycleMutation();

  const client = data?.client;

  if (!client) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.page}>
        <div
          className="ui-row"
          style={{
            gap: "1rem",
            padding: "1rem",
            justifyContent: "space-between",
          }}
        >
          <div className="ui-row" style={{ gap: "1rem" }}>
            <Avatar size={48} photo={client.user.photo}></Avatar>
            <div>
              <div style={{ fontSize: 20 }}>{client.user.name}</div>
              {/* <div>{client.user.email}</div> */}
            </div>
          </div>

          <Menu2
            align="end"
            items={[
              {
                id: "archive",
                label: "Archive client",
                color: "red",
              },
            ]}
            onSelect={async (optionId) => {
              //
            }}
          >
            <ResistanceButton>
              <i className="ri-more-2-line" />
            </ResistanceButton>
          </Menu2>
        </div>
        <div className="row">
          <select
            value={state.programId}
            onChange={(event) => {
              setState((draft) => {
                draft.programId = event.target.value;
              });
            }}
          >
            <option value="">Not selected</option>

            {programs.map((program) => {
              return (
                <option key={program.id} value={program.id}>
                  {program.name}
                </option>
              );
            })}
          </select>

          <Button
            disabled={!state.programId}
            onClick={async () => {
              if (!state.programId) return;

              createCycleMutation({
                variables: {
                  input: {
                    userId: client.user.id,
                    clientId: client.id,
                    programId: state.programId,
                  },
                },
                awaitRefetchQueries: true,
                refetchQueries: [
                  {
                    query: GetClientDocument,
                    variables: {
                      id,
                    },
                  },
                ],
              });
            }}
          >
            Create cycle
          </Button>
        </div>

        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              {client.cycles.map((cycle) => (
                <Link
                  to={`/workouts/${cycle.id}`}
                  className={styles.cycle}
                  key={cycle.id}
                >
                  <div>Cycle</div>
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <div style={{ fontSize: 12 }}>
                      Program: {cycle.program?.name}
                    </div>
                    <div style={{ fontSize: 12 }}>{cycle.createdAt}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* <div>
          {client.program ? (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/programs/${client.program.id}`}
            >
              {client.program.name} <i className="ri-arrow-right-up-line"></i>
            </Link>
          ) : (
            <Menu2
              align="end"
              items={programs.map((program) => {
                return {
                  id: program.id,
                  label: program.name,
                };
              })}
              onSelect={async (programId) => {
                await updateClientMutation({
                  variables: {
                    clientId: client.id,
                    input: {
                      programId,
                    },
                  },
                });
              }}
            >
              <Button size="small">
                <i className="ri-add-line" />
                Select program
              </Button>
            </Menu2>
          )}
        </div> */}
      </div>
    </div>
  );
};
