import { IResistance, IReps, IVariable } from "../../lib/types";

import { Weight } from "../../lib/_weight";
import { Reps } from "../../lib/_reps";
import { IWorkoutExercise, IWorkoutSet } from "../../lib/_workout";
import { lib } from "../../lib/_lib";
import { ParametersSheet } from "./ParametersSheet";

export interface WorkoutAddSetWrapperProps {
  exercise: IWorkoutExercise;
  variables: IVariable[];
  sets: Record<string, IWorkoutSet>;
  onAdd: (exercise: IWorkoutExercise, weight: IResistance, reps: IReps) => void;
  onClose: () => void;
}

export const WorkoutAddSetWrapper = ({
  exercise,
  variables,
  sets,
  onAdd,
  onClose,
}: WorkoutAddSetWrapperProps) => {
  const open = !!exercise;

  if (!exercise) {
    return null;
  }

  const lastSetId = exercise.sets[exercise.sets.length - 1];
  const lastSet = sets[lastSetId];

  let initialWeight = 0;
  let initialReps = 0;

  if (lastSet) {
    initialWeight = lib.WorkoutSet.computeNominalWeight(
      lastSet,
      lib.Variables.filter(variables, exercise.name)
    );

    initialReps = lastSet ? lastSet.reps.value : 0;
  }

  return (
    <ParametersSheet
      open={open}
      onOpen={(open) => {
        !open && onClose();
      }}
      initialWeight={initialWeight}
      initialReps={initialReps}
      onUpdate={(weight, reps) => {
        onAdd(exercise, Weight.nominal(weight), Reps.nominal(reps));
      }}
    />
  );
};
