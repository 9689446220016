import { ISessionExercise } from "routes/Session/schemas";
import {
  IVariable,
  IExercise,
  IProgram,
  ResistanceType,
  VariableType,
} from "./types";

export const Variables = {
  exists(variables: IVariable[], exercise: string, type: VariableType) {
    return variables.some(
      (variable) => variable.exercise === exercise && variable.type === type
    );
  },

  filter(variables: IVariable[], exercise: string) {
    return variables.filter((v) => v.exercise === exercise);
  },

  used(exercises: (IExercise | ISessionExercise)[], variable: IVariable) {
    return exercises.some(
      (exercise) =>
        exercise.name === variable.exercise &&
        exercise.sets.some((set) => set.weight.type === "p")
    );
  },

  reconcileVariables(program: IProgram) {
    const variables: string[] = [];

    console.log("todo");
  },
};
