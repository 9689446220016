import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { auth } from "../../lib/firebase";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper";
import { useAuthenticateMutation } from "graphql/types";

const googleProvider = new GoogleAuthProvider();

export const Signup = () => {
  const navigate = useNavigate();
  const { user } = useAuthenticatedWrapper();

  const [authenticate] = useAuthenticateMutation();

  return (
    <div className="ui-fullscreen ui-centered" style={{ gap: "1rem" }}>
      {user && !user.isAnonymous ? (
        <button
          className="button button--secondary"
          onClick={async () => {
            await signOut(auth);

            window.location.reload();
          }}
        >
          Sign out
        </button>
      ) : (
        <button
          className="button button--secondary"
          onClick={async () => {
            let credentials = await signInWithPopup(auth, googleProvider);

            if (credentials) {
              await authenticate({
                variables: {
                  token: await credentials.user.getIdToken(),
                },
              });

              navigate("/");
            }
          }}
        >
          Signin with Google
        </button>
      )}
    </div>
  );
};
