import React, { ReactNode } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import "./Menu.scss";

export type MenuItem = {
  label: string;
  id: string;
  icon?: string;
  color?: string;
  disabled?: boolean;
  children?: MenuItem[];
};

export interface MenuProps {
  items: MenuItem[];
  children?: ReactNode;
  align?: "start" | "end" | "center";
  onSelect: (id: string, childId?: string) => void;
}

export const Menu2 = ({ align, items, onSelect, children }: MenuProps) => {
  const hasIcons = items.some((v) => v.icon);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align={align}
          className="DropdownMenuContent"
          sideOffset={5}
        >
          {items.map((item) => {
            if (Array.isArray(item.children)) {
              return (
                <DropdownMenu.Sub key={item.id}>
                  <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
                    {item.icon && <i className={item.icon} />}
                    {item.label}
                    <div className="RightSlot">
                      <i className="ri-arrow-drop-right-line"></i>
                    </div>
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.SubContent
                      className="DropdownMenuSubContent"
                      sideOffset={2}
                      alignOffset={-5}
                    >
                      {item.children.map((child) => {
                        return (
                          <DropdownMenu.Item
                            key={child.id}
                            disabled={child.disabled}
                            className="DropdownMenuItem"
                            style={{ color: item.color }}
                            onClick={() => onSelect(item.id, child.id)}
                          >
                            {child.label}
                          </DropdownMenu.Item>
                        );
                      })}
                    </DropdownMenu.SubContent>
                  </DropdownMenu.Portal>
                </DropdownMenu.Sub>
              );
            }

            return (
              <DropdownMenu.Item
                key={item.id}
                disabled={item.disabled}
                className="DropdownMenuItem"
                style={{ color: item.color }}
                onClick={() => onSelect(item.id)}
              >
                {item.icon && <i className={item.icon} />}
                {item.label}
              </DropdownMenu.Item>
            );
          })}

          <DropdownMenu.Arrow className="DropdownMenuArrow" />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
