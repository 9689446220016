import { Root, Image, Fallback } from "@radix-ui/react-avatar";
import styles from "./Avatar.module.scss";
import Skeleton from "react-loading-skeleton";

const initials = (source: string) => {
  return source
    .split(" ")
    .map((v) => v.trim().substring(0, 1))
    .filter(Boolean)
    .join("")
    .toUpperCase();
};

export interface AvatarProps {
  loading?: boolean;
  photo?: string;
  name?: string;
  size?: number;
  radius?: number;
}

export const Avatar = ({
  loading,
  photo,
  name,
  size = 40,
  radius,
}: AvatarProps) => {
  if (loading) {
    return <Skeleton height={size} width={size} borderRadius={size} />;
  }

  let borderRadius = typeof radius === "number" ? radius : size;

  return (
    <Root
      className={styles.root}
      style={{ width: size, height: size, borderRadius }}
    >
      <Image className={styles.image} src={photo ?? ""} alt={name ?? ""} />
      <Fallback className={styles.fallback} delayMs={300}>
        {initials(name ?? "")}
      </Fallback>
    </Root>
  );
};
