import { useCallback } from "react";
import { useImmer } from "use-immer";
import {
  IProgram,
  IReps,
  ISet,
  IVariable,
  IResistance,
  VariableType,
  ResistanceType,
} from "./types";
import { exerciseNames } from "../data/Exercises";

import { nanoid } from "nanoid";

const isEmptyWeek = (state: IProgram, weekId: number) => {
  return state.exercises.filter((v) => v.week === weekId).length === 0;
};

export interface Path {
  day: number;
  week: number;
}

export const useBuilderState = (initial: IProgram) => {
  const [state, setState] = useImmer<IProgram>(initial);

  const updateName = useCallback((name: string) => {
    setState((draft) => {
      draft.name = name;
    });
  }, []);

  const updateWeeks = useCallback((count: number) => {
    setState((draft) => {
      draft.weeks = count;
    });
  }, []);

  const updateDays = useCallback((count: number) => {
    setState((draft) => {
      draft.days = count;
    });
  }, []);

  type UpdateSetInput = {
    weight?: Partial<IResistance>;
    reps?: Partial<IReps>;
  };
  /**
   * Todo: test this. havent tried yet.
   */
  const cleanupVariables = useCallback(() => {
    setState((draft) => {
      const usedIn = draft.exercises
        .filter((v) => {
          v.sets.some((v) => v.weight.type === ResistanceType.Percentage);
        })
        .map((exercise) => exercise.name);

      draft.variables = draft.variables.filter((variable) => {
        return usedIn.includes(variable.exercise);
      });
    });
  }, []);

  const updateSet = useCallback(
    (exerciseId: string, setId: string, input: UpdateSetInput) => {
      setState((draft) => {
        const exercise = draft.exercises.find((v) => v.id === exerciseId);
        const set = exercise?.sets.find((v) => v.id === setId);

        if (!set) return;

        if ("weight" in input) {
          set.weight = { ...set.weight, ...input.weight };
        }

        if ("reps" in input) {
          set.reps = { ...set.reps, ...input.reps };
        }
      });
    },
    []
  );

  const removeSet = useCallback((exerciseId: string, setId: string) => {
    setState((draft) => {
      let exercise = draft.exercises.find((v) => v.id === exerciseId);

      if (!exercise) return;

      exercise.sets = exercise.sets.filter((set) => set.id !== setId);
    });
  }, []);

  const removeExercise = useCallback((exerciseId: string) => {
    setState((draft) => {
      draft.exercises = draft.exercises.filter(
        (exercise) => exercise.id !== exerciseId
      );
    });
  }, []);

  const hasExercises = (weekId: number, dayId: number) => {
    return state.exercises.some((v) => v.week === weekId && v.day === dayId);
  };

  const updateVariableValue = (variableId: string, value: number) => {
    const index = state.variables.findIndex((v) => v.id === variableId);

    if (index === -1) {
      return;
    }

    setState((draft) => {
      draft.variables[index].value = value;
    });
  };

  const createVariable = (input: { type: VariableType; exercise: string }) => {
    const variable: IVariable = { ...input, id: nanoid(), value: 0 };

    setState((draft) => {
      draft.variables.push(variable);
    });
  };

  const createExercise = (week: number, day: number, name?: string) => {
    setState((draft) => {
      draft.exercises.push({
        id: nanoid(),
        day,
        week,
        name: name ?? exerciseNames[3].id,
        tags: [],
        sets: [],
      });
    });
  };

  const cloneWeek = useCallback(
    (sourceWeekId: number, targetWeekId: number) => {
      clearWeek(targetWeekId);

      setState((draft) => {
        if (isEmptyWeek(draft, sourceWeekId)) {
          return;
        }

        const newWeek = draft.exercises
          .filter((v) => v.week === sourceWeekId)
          .map((exercise) => {
            return {
              ...exercise,
              week: targetWeekId,
              id: nanoid(),
              sets: exercise.sets.map((set) => {
                return {
                  ...set,
                  id: nanoid(),
                };
              }),
            };
          });

        draft.exercises = [...draft.exercises, ...newWeek];
      });
    },
    []
  );

  const cloneDay = useCallback((sourcePath: Path, targetPath: Path) => {
    setState((draft) => {
      const day = draft.exercises.find(
        (v) => v.day === sourcePath.day && v.week === sourcePath.week
      );

      if (!day) {
        return;
      }

      const newDay = {
        ...day,
        week: targetPath.week,
        day: targetPath.day,
        id: nanoid(),
      };

      draft.exercises = [...draft.exercises, newDay];
    });
  }, []);

  const clearWeek = useCallback(
    (weekIndex: number) => [
      setState((draft) => {
        draft.exercises = draft.exercises.filter((v) => v.week !== weekIndex);
      }),
    ],
    []
  );

  return [
    state,
    {
      setState,
      updateName,
      updateDays,
      updateWeeks,
      updateSet,
      removeSet,
      removeExercise,
      hasExercises,
      updateVariableValue,
      createExercise,
      cloneWeek,
      cloneDay,
      clearWeek,
      createVariable,
    },
  ] as const;
};
