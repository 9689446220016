import { ISet } from "../../../lib/types";
import { Repetitions } from "./Repetitions";
import { Resistance } from "./Resistance";

import styles from "./SetComponent.module.scss";

export interface SetComponentProps {
  set: ISet;
  onWeightTypeChange: (type: string) => void;
  onWeightValueChange: (value: number) => void;
  onRepsValueChange: (value: number) => void;
  onRemove: () => void;
}

export const SetComponent = ({
  set,
  onWeightTypeChange,
  onWeightValueChange,
  onRepsValueChange,
  onRemove,
}: SetComponentProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.pointer}></div>
      <div style={{ width: 200 }}>
        <Resistance
          type={set.weight.type}
          value={set.weight.value}
          onTypeChange={onWeightTypeChange}
          onValueChange={onWeightValueChange}
        />
      </div>

      <Repetitions
        value={set.reps.value}
        onValueChange={(value) => onRepsValueChange(value)}
      />

      <div style={{ flex: 1 }} />

      <div
        className={styles.icon}
        onClick={() => {
          onRemove();
        }}
      >
        <i className="ri-indeterminate-circle-line"></i>
      </div>
    </div>
  );
};
