import { Variables } from "./_variables";
import { Set } from "./_set";
import { Reps } from "./_reps";
import { Resistance } from "./_weight";
import { WorkoutSet } from "./_workoutSet";

export const lib = {
  Variables,
  Set,
  Reps,
  Resistance,
  WorkoutSet,
};
