import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
// import config from "config";
import { createClient } from "graphql-ws";

export const wsLink = new GraphQLWsLink(
  createClient({
    url: "",
    on: {
      connecting() {
        console.log("WS: connecting...");
      },

      connected() {
        console.log("WS: connected");
      },
    },
  })
);
