import { Route, Routes } from "react-router-dom";
import { Client } from "./Client/Client";
import { Clients } from "./Clients";

export const ClientsRoute = () => {
  return (
    <Routes>
      <Route path=":id" element={<Client />} />
      <Route path="" element={<Clients />} />
    </Routes>
  );
};
