import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";

import { IProgram } from "../lib/types";
import { omit } from "lodash";

export interface ProgramType extends IProgram {
  id: string;
  version: number;
  owner: string;
  visibility: "private" | "public";
  description?: string;
}

export const ProgramConverter: FirestoreDataConverter<ProgramType> = {
  toFirestore(data: WithFieldValue<ProgramType>): DocumentData {
    return omit(data, "id");
  },

  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ProgramType {
    const data = snapshot.data(options);

    return {
      id: snapshot.id,
      ...data,
    } as ProgramType;
  },
};
