import { useImmer } from "use-immer";

import { map, mapValues } from "lodash";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAsync } from "react-use";
import { useCallback } from "react";
import { IReps, IResistance, SetStatus } from "./types";
import { nanoid } from "nanoid";
import { IExercise, IProgram, IVariable } from "./types";
import { createIndexedConverter } from "../utils";
import { User } from "firebase/auth";
import { formatISO } from "date-fns";
import {
  ISessionExercise,
  ISessionLog,
  ISessionSet,
} from "routes/Session/schemas";

interface CreateSetInput {
  exerciseId: string;
  weight: IResistance;
  reps: IReps;
}

interface UpdateSetInput
  extends Partial<Omit<ISessionSet, "id" | "createdAt" | "updatedAt">> {}

// interface CreateExerciseInput extends Pick<IWorkoutExercise, "name"> {}

export const useWorkout = (initial: ISessionLog) => {
  const [state, setState] = useImmer(initial);

  // const view = transformForPresentation(state);

  const createExercise = useCallback((name: string) => {
    setState((draft) => {
      const exercise: ISessionExercise = {
        id: nanoid(),
        name,
        createdAt: formatISO(new Date()),
        updatedAt: formatISO(new Date()),
        extra: true,
        sets: [],
      };

      draft.exercises.push(exercise);
    });
  }, []);

  const createSet = useCallback((input: CreateSetInput) => {
    setState((draft) => {
      const exercise = draft.exercises.find((v) => v.id === input.exerciseId);

      if (exercise) {
        const set: ISessionSet = {
          id: nanoid(),
          exerciseId: input.exerciseId,
          extra: true,
          status: SetStatus.None,
          createdAt: formatISO(new Date()),
          updatedAt: formatISO(new Date()),
          weight: input.weight,
          reps: input.reps,
        };

        exercise.sets.push(set);
      }
    });
  }, []);

  const updateSet = useCallback(
    (exerciseId: string, setId: string, input: UpdateSetInput) => {
      setState((draft) => {
        const exercise = draft.exercises.find((v) => v.id === exerciseId);

        if (exercise) {
          const setIndex = exercise.sets.findIndex((v) => v.id === setId);

          if (setIndex !== -1) {
            exercise.sets[setIndex] = {
              ...exercise.sets[setIndex],
              ...input,
              updatedAt: formatISO(new Date()),
            };
          }
        }
      });
    },
    []
  );

  const removeSet = useCallback((exerciseId: string, setId: string) => {
    setState((draft) => {
      // const set = draft.sets[setId];
      // if (!set.extra) return;
      // const exerciseId = set.exerciseId;
      // const sets = draft.exercises[exerciseId].sets.filter((v) => v !== setId);
      // draft.exercises[exerciseId].sets = sets;
    });
  }, []);

  const updateVariable = useCallback((variableId: string, value: number) => {
    setState((draft) => {
      if (!draft) return;

      let index = draft.variables.findIndex((v) => v.id === variableId);

      if (index !== -1) {
        draft.variables[index].value = value;
      }
    });
  }, []);

  return {
    state,
    setState,
    // view,
    updateSet,
    createSet,
    removeSet,
    createExercise,
    updateVariable,
  } as const;
};
