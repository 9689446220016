import { DBExercise, exerciseNames } from "data/Exercises"
import Fuse, { IFuseOptions } from "fuse.js"
import styles from "./SearchSheet.module.scss"
import { useEffect, useState } from "react"
import clsx from "clsx"
import { SheetButton } from "../../../../components/Layer/SheetButton"
import { Sheet } from "components/Layer/Sheet"
import { SheetContent } from "components/Layer/SheetContent"
import { AddExerciseContent } from "./AddExerciseContent"
import { useImmer } from "use-immer"

const fuseOptions: IFuseOptions<(typeof exerciseNames)[0]> = {
  keys: ["name"]
}

const fuse = new Fuse(exerciseNames, fuseOptions)
const limit = 10

type Exercise = (typeof exerciseNames)[0]

const Empty = () => {
  return <div>Nothing was found</div>
}

export interface SearchSheetProps {
  open: boolean
  onSelect: (exerciseKey: string) => void
  onOpen: (value: boolean) => void
}

export const SearchSheet = ({ open, onOpen, onSelect }: SearchSheetProps) => {
  const [query, setQuery] = useState<string>("")
  const [results, setResults] = useState<Exercise[]>([])
  const [selected, setSelected] = useState<string>("")

  // const [created, setCreated] = useImmer<DBExercise[]>([])
  // const allExercises = [...exerciseNames, ...created]

  useEffect(() => {
    setQuery("")
    setSelected("")
  }, [open])

  useEffect(() => {
    if (query.length === 0) {
      setResults(exerciseNames.slice(0, limit))
    } else {
      setResults(fuse.search(query, { limit }).map((v) => v.item))
    }
  }, [query])

  const [isCreating, setIsCreating] = useState(false)

  return (
    <Sheet open={open} onOpen={onOpen}>
      <SheetContent>
        {isCreating ? (
          <AddExerciseContent
            onCancel={() => {
              setIsCreating(false)
            }}
            onCreate={(name) => {
              onSelect(name)
              onOpen(false)

              setIsCreating(false)
            }}
          />
        ) : (
          <div className={styles.container}>
            <div>
              <input
                className={clsx("input-reset", styles.input)}
                placeholder="Search..."
                value={query}
                onChange={(event) => {
                  setQuery(event.target.value)
                  setSelected("")
                }}
              />
            </div>

            <div className={styles.listContainer}>
              <div className={styles.list}>
                {results.map((exercise) => {
                  return (
                    <div
                      className={clsx(
                        styles.button,
                        exercise.id === selected && styles.selected
                      )}
                      key={exercise.id}
                      onClick={() => {
                        setSelected(exercise.id)
                      }}
                    >
                      {exercise.name}
                    </div>
                  )
                })}

                <div
                  className={clsx(styles.button)}
                  style={{ paddingLeft: "0.5rem" }}
                  onClick={() => {
                    setIsCreating(true)
                  }}
                >
                  <i
                    style={{
                      marginRight: "0.25rem",
                      borderRadius: "1rem",
                      padding: "4px"
                    }}
                    className="ri-add-line"
                  ></i>
                  Add new exercise
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                width: "100%",
                paddingTop: "1rem"
              }}
            >
              <SheetButton
                disabled={!selected}
                use="normal"
                onClick={() => {
                  onSelect(selected)
                  onOpen(false)
                }}
              >
                Next
              </SheetButton>
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  )
}
