import clsx from "clsx";
import { clamp, round } from "lodash";
import { CSSProperties, useEffect, useState } from "react";

export interface NumericUncontrolledInputProps {
  disabled?: boolean;
  min?: number;
  max?: number;
  type?: "float" | "integer";
  className?: string;
  style?: CSSProperties;
  value: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

const FLOAT_REGEX = /[+-]?([0-9]*[.])?[0-9]+/;
const INTEGER_REGEX = /[+-]?[0-9]+/;

function extractNumber(value: string, regex: RegExp) {
  const match = value.match(regex);

  if (match) {
    const float = Number.parseFloat(match[0]);

    if (Number.isFinite(float)) {
      return float;
    }
  }

  return null;
}

export const NumericUncontrolledInput = ({
  disabled,
  type = "float",
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  className,
  style,
  value,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: NumericUncontrolledInputProps) => {
  const [internalValue, setInternalValue] = useState(value.toString());

  const regex = type === "float" ? FLOAT_REGEX : INTEGER_REGEX;
  const precision = type == "float" ? 2 : 0;

  useEffect(() => {
    if (value.toString() !== internalValue) {
      setInternalValue(value.toString());
    }
  }, [value]);

  return (
    <input
      {...rest}
      disabled={disabled}
      style={style}
      className={clsx("input-reset", className)}
      value={internalValue}
      onChange={(event) => {
        const value = event.target.value;
        const number = extractNumber(value, regex);

        setInternalValue(value);

        if (typeof number === "number") {
          onChange(number);
        }
      }}
      inputMode="decimal"
      onFocus={onFocus}
      onBlur={() => {
        const number = extractNumber(internalValue, regex);

        if (typeof number === "number") {
          const rounded = round(number, precision);
          const output = clamp(rounded, min, max);

          onChange(output);
          setInternalValue(output.toString());
        } else {
          onChange(0);
          setInternalValue("0");
        }

        onBlur?.();
      }}
    />
  );
};
