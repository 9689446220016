const config =
  process.env.NODE_ENV === "development"
    ? {
        apiUrl: "http://localhost:3007",
      }
    : {
        apiUrl: "https://club-server-r26sqd4fta-ey.a.run.app",
      };

export default config;
