import { useImmer } from "use-immer";

import { map, mapValues } from "lodash";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { useAsync } from "react-use";
import { useCallback } from "react";
import { IReps, IResistance, SetStatus } from "./types";
import { nanoid } from "nanoid";
import { IExercise, IProgram, IVariable } from "../lib/types";
import { createIndexedConverter } from "../utils";
import { User } from "firebase/auth";
import { formatISO } from "date-fns";

export interface IWorkoutSet {
  id: string;
  exerciseId: string;
  weight: IResistance;
  reps: IReps;
  overwriteWeight?: IResistance;
  overwriteReps?: IReps;
  status: SetStatus;
  updatedAt?: number;
  createdAt: number;
  extra: boolean;
}

export interface IWorkoutExercise {
  id: string;
  name: string;
  extra: boolean;
  superset?: boolean;
  sets: string[];
  updatedAt?: number;
  createdAt: number;
}

export interface IWorkoutSession {
  startedAt?: number;
  endedAt?: number;
  moodBefore?: string;
  moodAfter?: string;
  key: string;
  cycleId: string;
}

export interface IWorkoutCycle {
  id: string;
  name: string;
  userId: string;
  variables: IVariable[];
  sets: Record<string, IWorkoutSet>;
  exercises: Record<string, IWorkoutExercise>;
  schedule: Record<string, string[]>;
  programId: string;
  program: IProgram;
  programVersion: number;
  createdAt: number;
  updatedAt?: any;
  startsOn?: any;
  previous?: {
    id: string;
    programVersion: number;
  };
}

export const createWorkoutCycle = (
  list: IExercise[],
  variables: IVariable[]
): Pick<IWorkoutCycle, "exercises" | "schedule" | "sets" | "variables"> => {
  let exercises: Record<string, IWorkoutExercise> = {};
  let sets: Record<string, IWorkoutSet> = {};
  let schedule: Record<string, string[]> = {};

  list.forEach((exercise) => {
    let scheduleKey = exercise.week + "-" + exercise.day;
    /**
     * Updating schedule to include exercise
     */
    schedule[scheduleKey] = [...(schedule[scheduleKey] || []), exercise.id];

    /**
     * Updating exercises
     */
    const workoutExercise: IWorkoutExercise = {
      id: exercise.id,
      name: exercise.name,
      extra: false,
      sets: [],
      createdAt: Date.now(),
    };

    exercise.sets.forEach((set) => {
      const workoutSet = {
        id: set.id,
        extra: false,
        weight: set.weight,
        reps: set.reps,
        exerciseId: exercise.id,
        status: SetStatus.None,
        createdAt: Date.now(),
      };

      workoutExercise.sets.push(workoutSet.id);
      sets[workoutSet.id] = workoutSet;
    });

    exercises[workoutExercise.id] = workoutExercise;
  });

  return { exercises, sets, schedule, variables };
};

export const createWorkoutSession = (
  _exercises: IExercise[],
  variables: IVariable[],
  week: number,
  day: number
) => {
  const exercises = _exercises
    .filter((exercise) => {
      return exercise.week === week && exercise.day === day;
    })
    .map((exercise) => {
      return {
        id: nanoid(),
        name: exercise.name,
        extra: false,
        sets: exercise.sets.map((set) => {
          return {
            id: nanoid(),
            // exerciseId: ID_SCHEMA,
            weight: set.weight,
            reps: set.reps,
            // overwriteWeight: WeightSchema.optional(),
            // overwriteReps: RepsSchema.optional(),
            status: SetStatus.None,
            extra: false,
            createdAt: formatISO(new Date()),
            updatedAt: formatISO(new Date()),
          };
        }),
        createdAt: formatISO(new Date()),
        updatedAt: formatISO(new Date()),
      };
    });

  return { exercises, variables };
};

const transformForPresentation = (workout: IWorkoutCycle) => {
  return mapValues(workout.schedule, (exerciseIds) => {
    return map(exerciseIds, (exerciseId) => {
      const exercise = workout.exercises[exerciseId];
      const sets = exercise.sets.map((setId) => workout.sets[setId]);

      return { id: exercise.id, exercise, sets };
    });
  });
};

export type IWorkoutView = ReturnType<typeof transformForPresentation>;

interface CreateSetInput
  extends Pick<IWorkoutSet, "exerciseId" | "weight" | "reps"> {
  exerciseId: string;
  weight: IResistance;
  reps: IReps;
}

interface UpdateSetInput extends Partial<Omit<IWorkoutSet, "id">> {}

interface CreateExerciseInput extends Pick<IWorkoutExercise, "name"> {}

export const useWorkout = (source: IWorkoutCycle) => {
  const [state, setState] = useImmer(source);

  const view = transformForPresentation(state);

  const createExercise = useCallback(
    (input: CreateExerciseInput, scheduleKey: string) => {
      setState((draft) => {
        const exercise: IWorkoutExercise = {
          ...input,
          id: nanoid(),
          createdAt: Date.now(),
          extra: true,
          sets: [],
        };

        draft.exercises[exercise.id] = exercise;
        draft.schedule[scheduleKey] = draft.schedule[scheduleKey] || [];
        draft.schedule[scheduleKey].push(exercise.id);
      });
    },
    []
  );

  const createSet = useCallback((input: CreateSetInput) => {
    setState((draft) => {
      const set: IWorkoutSet = {
        ...input,
        id: nanoid(),
        createdAt: Date.now(),
        extra: true,
        status: SetStatus.None,
      };

      draft.exercises[set.exerciseId].sets.push(set.id);
      draft.sets[set.id] = set;
    });
  }, []);

  const updateSet = useCallback((id: string, input: UpdateSetInput) => {
    setState((draft) => {
      let set = draft.sets[id];

      if (!set) return;

      draft.sets[set.id] = {
        ...set,
        ...input,
        updatedAt: Date.now(),
      };
    });
  }, []);

  const removeSet = useCallback((setId: string) => {
    setState((draft) => {
      const set = draft.sets[setId];

      if (!set.extra) return;

      const exerciseId = set.exerciseId;
      const sets = draft.exercises[exerciseId].sets.filter((v) => v !== setId);

      draft.exercises[exerciseId].sets = sets;
    });
  }, []);

  const updateVariable = useCallback((variableId: string, value: number) => {
    setState((draft) => {
      if (!draft) return;

      let index = draft.variables.findIndex((v) => v.id === variableId);

      if (index === -1) return;

      draft.variables[index].value = value;
    });
  }, []);

  return {
    state,
    setState,
    view,
    updateSet,
    createSet,
    removeSet,
    createExercise,
    updateVariable,
  };
};

export const WorkoutConverter = createIndexedConverter<IWorkoutCycle>();

export const useGetWorkout = (id: string) => {
  return useAsync(async () => {
    if (!id) return;

    const snapshot = await getDoc(
      doc(db, `/workout_cycles/${id}`).withConverter(WorkoutConverter)
    );

    const data = snapshot.data() as IWorkoutCycle;

    return data;
  }, [id]);
};

export const useGetWorkouts = (user: User | null) => {
  return useAsync(async () => {
    if (!user) return;

    const snapshot = await getDocs(
      query(
        collection(db, `/workout_cycles`),
        where("userId", "==", user.uid)
      ).withConverter(WorkoutConverter)
    );

    const workouts = snapshot.docs.map((doc) => doc.data());

    return workouts;
  }, [user]);
};

export const useUpdateWorkout = () => {
  return useCallback(async (id: string, data: Partial<IWorkoutCycle>) => {
    const ref = doc(db, `/workout_cycles/${id}`);

    await updateDoc(ref, {
      ...data,
      updatedAt: serverTimestamp(),
    });
  }, []);
};
