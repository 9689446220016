import { useEffect, useRef, useState } from "react";
import styles from "./InputStepper.module.scss";
import { InputButton } from "../InputButton";
import { InputContainer } from "../InputContainer";
import { NumericUncontrolledInput } from "components/NumericUncontrolledInput";
import { clamp } from "lodash";
import clsx from "clsx";

export interface Option {
  value: string;
  label: string;
}

export interface InputStepperProps {
  value: number;
  max?: number;
  min?: number;
  fill?: boolean;
  disabled?: boolean;
  size?: "small" | "medium";
  onChange: (input: number) => void;
}

export const InputStepper = ({
  value,
  size = "small",
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  fill = false,
  disabled = false,
  onChange,
}: InputStepperProps) => {
  const [focused, setFocused] = useState(false);

  const interval = useRef<NodeJS.Timer>();

  const onMouseDown = (type: string) => {
    if (interval.current) {
      clearInterval(interval.current);
    }

    let current = value;

    interval.current = setInterval(() => {
      current += type === "increment" ? 1 : -1;

      update(current);
    }, 200);
  };

  const onMouseUp = () => {
    clearInterval(interval.current);
  };

  const update = (value: number) => {
    // if (value >= min && value <= max) {
    onChange(clamp(value, min, max));
  };

  return (
    <InputContainer focused={focused}>
      <InputButton
        size={size}
        disabled={typeof min === "number" && value <= min}
        onClick={() => update(value - 1)}
        onMouseDown={() => onMouseDown("decrement")}
        onTouchStart={() => onMouseDown("decrement")}
        onMouseUp={() => onMouseUp()}
        onMouseLeave={() => onMouseUp()}
        onTouchEnd={() => onMouseUp()}
        onTouchCancel={() => onMouseUp()}
      >
        <i style={{ padding: "0px 2px" }} className="ri-subtract-line"></i>
      </InputButton>

      <NumericUncontrolledInput
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        className={clsx(styles.input, styles[size], fill && styles.fill)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(number) => update(number)}
      />

      <InputButton
        size={size}
        disabled={typeof max === "number" && value >= max}
        onClick={() => {
          onChange(value + 1);
        }}
        onMouseDown={() => onMouseDown("increment")}
        onTouchStart={() => onMouseDown("increment")}
        onMouseUp={() => onMouseUp()}
        onMouseLeave={() => onMouseUp()}
        onTouchEnd={() => onMouseUp()}
        onTouchCancel={() => onMouseUp()}
      >
        <i style={{ padding: "0px 2px" }} className="ri-add-line"></i>
      </InputButton>
    </InputContainer>
  );
};
