import styles from "./Clients.module.scss";

import { useGetClientsQuery } from "graphql/types";
import { Avatar } from "components/Avatar/Avatar";
import { Button } from "components/Button/Button";
import { useNavigate } from "react-router-dom";
import { Tabs } from "../Manage/Tabs";

export const Clients = () => {
  const { data, loading } = useGetClientsQuery();

  const clients = data?.clients ?? [];
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <Tabs
        options={[
          {
            label: "Clients",
            value: "clients",
          },
          {
            label: "Programs",
            value: "programs",
          },
        ]}
        onChange={() => {}}
        value={"clients"}
      ></Tabs>

      <h2>Clients</h2>

      <div>
        <Button size="small">Add new client</Button>
      </div>

      <div className={styles.list}>
        {clients.map((client) => {
          return (
            <div
              className={styles.item}
              key={client.id}
              onClick={() => {
                navigate(`/manage/app/clients/${client.id}`);
              }}
            >
              <div className={styles.about}>
                <Avatar size={48} photo={client.user.photo} />
                <div>
                  <div className={styles.title}>{client.user.name}</div>
                  <div className={styles.description}>{client.user.email}</div>
                </div>
              </div>

              <div className={styles.more}>
                <i className="ri-arrow-right-line"></i>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
