export enum RepetitionsType {
  Units = "v",
  Duration = "t",
  Distance = "d",
}

export enum VariableType {
  RepetitionMax = "r",
}

export enum ResistanceType {
  Units = "v",
  Bodyweight = "b",
  NotApplicable = "n",
  Percentage = "p",
}

export interface IResistance {
  type: ResistanceType;
  value: number;
}

export interface IReps {
  type: RepetitionsType;
  value: number;
}

export interface IRest {
  duration: number; // Duration in seconds
}

export interface INotes {
  text: string;
}

export interface IVariable {
  id: string;
  exercise: string;
  value: number;
  type: VariableType;
}

export interface ISet {
  id: string;
  weight: IResistance;
  reps: IReps;
}

export interface IExercise {
  id: string;
  day: number;
  week: number;
  name: string;
  notes?: string;
  group?: string;
  superset?: string;
  tags?: string[];
  rest?: number;
  sets: ISet[];
}

export type IProgram = {
  /**
   * Builder version
   */
  // builder: number;
  // public?: true; // @todo
  name: string;
  /**
   * Length of the program in weeks
   */
  weeks: number;
  /**
   * Number of training days per week
   */
  days: number;
  variables: IVariable[];
  exercises: IExercise[];
  // owner: string;
  // visibility: string;
};

export enum SetStatus {
  None = "",
  Done = "d",
  Canceled = "c",
  Failed = "f",
}

export interface IWorkout {
  id: string;
  name: string;
  programId: string;
  createdAt: number;
}
