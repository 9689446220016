import { PropsWithChildren } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useGetClientQuery, useGetSelfQuery } from "graphql/types";
import { Avatar } from "components/Avatar/Avatar";
import { Tabs } from "./Tabs";

export interface LayoutProps extends PropsWithChildren {}

export interface SidebarLinkProps {
  title: string;
  path: string;
  icon: string;
}

const SidebarLink = (props: SidebarLinkProps) => {
  return (
    <Link to={props.path} className={styles.item}>
      <div className={styles.icon}>
        <i className={props.icon}></i>
      </div>
      <div className={styles.title}>{props.title}</div>
    </Link>
  );
};

export const UserLink = () => {
  const { data } = useGetSelfQuery();

  return (
    <div>
      <Avatar photo={data?.self.photo} size={36} />
      <div>{data?.self.name}</div>
    </div>
  );
};

export const Layout = (props: LayoutProps) => {
  const params = useParams<any>();
  const page = params["*"]?.split("/")[0] ?? "";

  return (
    <div className={styles.layout}>
      <div>{props.children}</div>
    </div>
  );
};
