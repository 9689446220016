import { Route, Routes } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Home } from "./Home/Home";
import { ManageRouter } from "./Manage/ManageRouter";

export const Manage = () => {
  return (
    <div>
      <Routes>
        <Route path="" element={<Dashboard />} />
        <Route path="home" element={<Home />} />
        {/* <Route path="more/*" element={<ManageRouter />} /> */}
        <Route path="app/*" element={<ManageRouter />} />
      </Routes>
    </div>
  );
};
