import { useEffect, useState } from "react";
import { ProgressLine } from "../../components/ProgressLine/ProgressLine";
import { WorkoutExercise } from "./WorkoutExercise/WorkoutExercise";
import { Party } from "./Party";
import { DaySelector } from "./DaySelector/DaySelector";
import { useImmerCached } from "../../utils";
import { WorkoutMenu } from "./WorkoutMenu";
import { AppPage } from "../../app/AppPage/AppPage";
import { PageSection } from "../../app/PageSection";
import { SetStatus } from "../../lib/types";
import { WorkoutAddSetWrapper } from "./WorkoutAddSetWrapper";
import { WorkoutEditSetWrapper } from "./WorkoutEditSetWrapper";
import {
  IWorkoutCycle,
  IWorkoutSet,
  useGetWorkout,
  useUpdateWorkout,
  useWorkout,
} from "../../lib/_workout";
import { lib } from "../../lib/_lib";
import { useParams } from "react-router-dom";
import { VariablesComponent } from "./Variables";
import { CreateExtraExercise } from "workout_modals/CreateExtraExercise/CreateExtraExercise";
import { SearchSheet } from "./SearchSheet/SearchSheet";

export const Workout = () => {
  const { id = "" } = useParams();
  const data = useGetWorkout(id);

  if (!data.value) {
    return null;
  }

  return <Base id={id} data={data.value} />;
};

export interface BaseProps {
  id: string;
  data: IWorkoutCycle;
}

const counts = (sets: IWorkoutSet[]) => {
  const complete = sets.filter((v) => v.status === SetStatus.Done).length;
  const total = sets.length;

  return [complete, total, 100 * (complete / total)] as const;
};

const Base = (props: BaseProps) => {
  const [page, setPage] = useImmerCached(`page:${props.id}`, {
    day: 0,
    week: 0,
  });

  const [additionalSetExerciseId, setAdditionalSetExerciseId] = useState("");
  const [editedSetId, setEditedSetId] = useState("");
  const [showCreateExtraExercise, setShowCreateExtraExercise] = useState(false);

  const workout = useWorkout(props.data);

  const scheduleKey = page.week + "-" + page.day;
  const schedule = workout.view[scheduleKey] ?? [];

  const updateWorkout = useUpdateWorkout();

  useEffect(() => {
    if (!workout.state) return;

    updateWorkout(props.id, workout.state);
  }, [JSON.stringify(workout.state), props.id, updateWorkout]);

  const [party, setParty] = useState(false);

  const [completed, total, progress] = counts(schedule.flatMap((v) => v.sets));

  const isDone = completed === total && completed !== 0;

  return (
    <AppPage
      backHref="/"
      more={
        <WorkoutMenu
          onClick={(id) => {
            if (id === "start-new-cycle") {
              // createCycle(user!.uid, id!);
              return;
            }
          }}
        />
      }
    >
      {isDone && party && (
        <Party
          onComplete={() => {
            setParty(false);
          }}
        />
      )}

      <PageSection>
        <DaySelector
          selectedDayIndex={page.day}
          selectedWeekIndex={page.week}
          program={workout.state.program}
          view={workout.view}
          onSelect={(week, day) => {
            setPage({ week, day });
            setParty(false);
          }}
        />

        <VariablesComponent
          workout={workout.state}
          onVariableUpdate={workout.updateVariable}
        />

        {schedule.length > 0 && (
          <div
            style={{ gap: "1rem", display: "flex", flexDirection: "column" }}
          >
            <ProgressLine
              backgroundColor="#f3f3f3"
              visualParts={[
                {
                  color: "#50ce46",
                  percentage: progress.toFixed(2) + "%",
                },
              ]}
            />

            {schedule.map(({ id, exercise, sets }) => (
              <WorkoutExercise
                key={id}
                exercise={exercise}
                variables={lib.Variables.filter(
                  workout.state.variables,
                  exercise.name
                )}
                sets={sets}
                onSetStatusChange={(set, status) => {
                  workout.updateSet(set.id, {
                    status,
                  });

                  setParty(true);
                }}
                onSetClick={(set) => {
                  setEditedSetId(set.id);
                }}
                onSetCreate={() => {
                  setAdditionalSetExerciseId(exercise.id);
                }}
              />
            ))}
          </div>
        )}
        <div
          style={{
            padding: "2rem",
            // marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="button button--secondary"
            style={{ lineHeight: "1.5rem" }}
            onClick={() => {
              setShowCreateExtraExercise(true);
            }}
          >
            Add an exercise
          </button>
        </div>
      </PageSection>

      <WorkoutAddSetWrapper
        variables={workout.state.variables}
        exercise={workout.state.exercises[additionalSetExerciseId]}
        sets={workout.state.sets}
        onClose={() => {
          setAdditionalSetExerciseId("");
        }}
        onAdd={(exercise, weight, reps) => {
          workout.createSet({
            weight,
            reps,
            exerciseId: exercise.id,
          });

          setAdditionalSetExerciseId("");
        }}
      />

      <WorkoutEditSetWrapper
        variables={workout.state.variables}
        set={workout.state.sets[editedSetId]}
        onClose={() => {
          setEditedSetId("");
        }}
        onEdit={(set, overwriteWeight, overwriteReps) => {
          workout.updateSet(set.id, {
            overwriteWeight,
            overwriteReps,
          });

          setEditedSetId("");
        }}
      />

      <SearchSheet
        open={showCreateExtraExercise}
        onOpen={setShowCreateExtraExercise}
        onSelect={(key) => {
          workout.createExercise(
            {
              name: key,
            },
            scheduleKey
          );
        }}
      />
    </AppPage>
  );
};
