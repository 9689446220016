import { exerciseNames } from "../../../data/Exercises"
import { IExercise, ResistanceType } from "../../../lib/types"
import clsx from "clsx"
import { SetComponent } from "../SetComponent/SetComponent"

import styles from "./Exercise.module.scss"
import { Menu2 } from "components/Menu2"
import { Button } from "components/Button/Button"

export interface ExerciseSelectProps {
  value: string
  onChange: (value: string) => void
}

const ExerciseSelect = ({ value, onChange }: ExerciseSelectProps) => {
  return (
    <div className={styles.customSelect}>
      <select
        className={styles.select}
        value={value}
        onChange={(event) => {
          onChange(event.target.value)
        }}
      >
        {exerciseNames.map((exerciseName) => (
          <option key={exerciseName.id} value={exerciseName.id}>
            {exerciseName.name}
          </option>
        ))}
      </select>
    </div>
  )
}

interface Props {
  exercise: IExercise
  onChangeName: (name: string) => void
  onCreateSet: () => void
  onWeightTypeChange: (setId: string, value: ResistanceType) => void
  onWeightValueChange: (setId: string, value: number) => void
  onRepsValueChange: (setId: string, value: number) => void
  onRemove: () => void
  onRemoveSet: (setId: string) => void
}

const settingsOptions = [
  {
    id: "add-note",
    icon: "ri-pencil-line",
    label: "Add/Edit note"
  },
  {
    id: "remove",
    icon: "ri-close-line",
    label: "Remove exercise"
  }
]

export const ExerciseComponent = ({
  exercise,
  onCreateSet,
  onChangeName,
  onWeightTypeChange,
  onWeightValueChange,
  onRepsValueChange,
  onRemove,
  onRemoveSet
}: Props) => (
  <div
    key={exercise.id}
    id={`exercise-${exercise.id}`}
    className={clsx("card", styles.component)}
  >
    <div className="row" style={{ gap: "0.5rem", paddingRight: "0.25rem" }}>
      <ExerciseSelect
        value={exercise.name}
        onChange={(name) => onChangeName(name)}
      />

      <button
        className="button button--small button--secondary"
        onClick={() => {
          onCreateSet()
        }}
      >
        <i className="ri-add-line" style={{ paddingRight: "0.25rem" }}></i> Add
        set
      </button>

      {/* <button className="button button--small button--secondary">
        <i className="ri-more-2-line"></i>
      </button> */}

      <Menu2
        align="end"
        items={settingsOptions}
        onSelect={(optionId) => {
          if (optionId === "add-note") {
            return
          }

          if (optionId === "remove") {
            onRemove()
            return
          }
        }}
      >
        <Button use="secondary" size="small">
          <i className="ri-more-2-line"></i>
        </Button>
      </Menu2>
    </div>

    {exercise.sets.length > 0 && (
      <div className={styles.heading}>
        <div />
        <div className={styles.cell}>Weight</div>
        <div className={styles.cell}>Reps</div>
        <div />
      </div>
    )}

    <div className={styles.sets}>
      {exercise.sets.map((set) => (
        <SetComponent
          set={set}
          key={set.id}
          onWeightTypeChange={(type) => {
            onWeightTypeChange(set.id, type as ResistanceType)
          }}
          onWeightValueChange={(value) => {
            onWeightValueChange(set.id, value)
          }}
          onRepsValueChange={(value) => {
            onRepsValueChange(set.id, value)
          }}
          onRemove={() => {
            onRemoveSet(set.id)
          }}
        />
      ))}
    </div>

    {/* <button
      className="button button--small button--secondary"
      style={{
        marginTop: "0.25rem",
        float: "right",
        background: "transparent",
      }}
      onClick={() => {
        onCreateSet();
      }}
    >
      <i className="ri-add-line" style={{ paddingRight: "0.25rem" }}></i>{" "}
      Superset
    </button> */}
  </div>
)
