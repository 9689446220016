import {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { omit } from "lodash";
import { useEffect } from "react";
import { useImmer } from "use-immer";

export const generateId = () => {
  return Date.now().toString(36) + "." + Math.random().toString(36);
};

export const repeat = (count: number) => {
  return Array.from({ length: count }).map((_, index) => index);
};

export const matrix = (width: number, height: number) => {
  let total = width * height;
  let result = [];

  for (let i = 0; i < total; i++) {
    let x = Math.floor(i / width);
    let y = i % width;

    result[i] = [x, y];
  }

  return result;
};

export const sequence = (
  from: number,
  to: number,
  prefix: string = "",
  suffix: string = ""
) => {
  return repeat(to - from).map((v) => prefix + (from + v) + suffix);
};

export const safeJSONParse = (data: any) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.warn(error);

    return undefined;
  }
};

// function useFirebaseCollection<T>(
//   path: string,
//   initialState: T,
//   skip: boolean = false
// ) {
//   const [state, setState] = useState<T>(initialState);

//   const fetch = async () => {
//     const snapshot = await getDocs(collection(db, path));

//     const data = snapshot.docs.map((doc) => {
//       return doc.data();
//     });

//     setState(data as T);
//   };

//   useEffect(() => {
//     if (skip) return;

//     fetch();
//   }, [skip]);

//   return [state, fetch];
// }

export function createIndexedConverter<T>(): FirestoreDataConverter<T> {
  type F = T & { id: string };

  return {
    toFirestore(data: WithFieldValue<F>): DocumentData {
      return omit(data, "id") as DocumentData;
    },

    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): F {
      const data = snapshot.data(options);

      return {
        id: snapshot.id,
        ...data,
      } as F;
    },
  };
}

export const useImmerCached = (cacheKey: string, initial?: any) => {
  const [state, setState] = useImmer(
    safeJSONParse(localStorage.getItem(cacheKey)) || initial
  );

  useEffect(() => {
    console.log("Caching", cacheKey);

    localStorage.setItem(cacheKey, JSON.stringify(state));
  }, [cacheKey, state]);

  return [state, setState] as const;
};
