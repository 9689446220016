import "remixicon/fonts/remixicon.css"
import { exerciseDB } from "../../../data/Exercises"

import { ExerciseComponent } from "./../ExerciseComponent"
import {
  IExercise,
  ISet,
  ResistanceType,
  VariableType
} from "../../../lib/types"
import { lib } from "../../../lib/_lib"
import { useProgramContext } from "../ProgramContext"

interface Props {
  exercises: IExercise[]
}

export function ExerciseList({ exercises }: Props) {
  const [state, controls] = useProgramContext()

  return (
    <div
      style={{
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        paddingTop: "1rem",
        paddingBottom: "1rem"
      }}
    >
      {exercises.map((exercise) => {
        return (
          <ExerciseComponent
            exercise={exercise}
            key={exercise.id}
            onChangeName={(name) => {
              controls.setState((draft) => {
                let target = draft.exercises.find((v) => v.id === exercise.id)

                if (target) {
                  target.name = name
                }
              })
            }}
            onCreateSet={() => {
              controls.setState((draft) => {
                const target = draft.exercises.find((v) => v.id === exercise.id)

                if (!target) return

                const info = exerciseDB[exercise.name]
                const setsCount = target.sets.length
                const lastSet = target.sets[setsCount - 1]

                let set: ISet

                if (setsCount === 0) {
                  if (info.weights) {
                    set = lib.Set.createSet(lib.Resistance.nominal(0), 10)
                  } else {
                    set = lib.Set.createSet(lib.Resistance.notApplicable(), 10)
                  }
                } else {
                  set = lib.Set.cloneSet(lastSet)
                }

                target.sets.push(set)
              })
            }}
            onRepsValueChange={(setId, value) => {
              controls.updateSet(exercise.id, setId, {
                reps: {
                  value
                }
              })
            }}
            onWeightTypeChange={(setId, type) => {
              controls.updateSet(exercise.id, setId, {
                weight: {
                  type
                }
              })

              if (type === ResistanceType.Percentage) {
                const exists = lib.Variables.exists(
                  state.variables,
                  exercise.name,
                  VariableType.RepetitionMax
                )

                if (!exists) {
                  controls.createVariable({
                    exercise: exercise.name,
                    type: VariableType.RepetitionMax
                  })
                }
              }
            }}
            onWeightValueChange={(setId, value) => {
              controls.updateSet(exercise.id, setId, {
                weight: {
                  value
                }
              })
            }}
            onRemove={() => {
              controls.removeExercise(exercise.id)
            }}
            onRemoveSet={(setId) => {
              controls.removeSet(exercise.id, setId)
            }}
          />
        )
      })}
    </div>
  )
}
