import { VariableType } from "lib/types"
import { useProgramContext } from "../ProgramContext"
import { getExercise } from "data/Exercises"
import { PageSection } from "app/PageSection"
import { VariableRow } from "./VariableRow"

export const VariableList = () => {
  const [state, controls] = useProgramContext()

  const variables = state.variables.map((variable) => {
    let label = ""

    if (variable.type === VariableType.RepetitionMax) {
      const exercise = getExercise(variable.exercise)

      if (exercise) {
        label = `${exercise.name} 1RM`
      }
    }

    return { ...variable, label }
  })

  return variables.length > 0 ? (
    <PageSection label="Variables">
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column"
        }}
      >
        {variables.map((variable) => (
          <VariableRow
            variable={variable}
            key={variable.id}
            onValueChange={(value) => {
              controls.updateVariableValue(variable.id, value)
            }}
          />
        ))}
      </div>
    </PageSection>
  ) : null
}
