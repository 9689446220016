import clsx from "clsx";
import styles from "./Button.module.scss";
import { PropsWithChildren, Ref, RefObject, forwardRef } from "react";

export interface ButtonProps extends PropsWithChildren {
  use?: "primary" | "secondary";
  size?: "small" | "medium" | "large";
  corners?: "rounded" | "squared";
  disabled?: boolean;
  onClick?: () => void;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      use = "secondary",
      size = "small",
      corners = "rounded",
      disabled = false,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          styles.button,
          styles[use],
          styles[size],
          styles[corners],
          disabled && styles.disabled
        )}
        disabled={disabled}
        {...rest}
      />
    );
  }
);
