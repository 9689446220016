import { useGetProgramsQuery } from "graphql/types";
import { useEffect } from "react";
import { Link } from "react-router-dom";

export const Programs = () => {
  const { data, refetch } = useGetProgramsQuery();

  useEffect(() => {
    const listen = () => {
      refetch();
    };

    window.addEventListener("focus", listen);

    return () => {
      window.removeEventListener("focus", listen);
    };
  }, [refetch]);

  const programs = data?.programs ?? [];

  return (
    <div>
      <h2>Programs</h2>
      <div>
        {programs.map((program) => {
          return (
            <Link key={program.id} target="_blank" to={"/programs"}>
              {program.id} {program.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};
