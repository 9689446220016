import { useState } from "react";
import { NumericUncontrolledInput } from "../../../components/NumericUncontrolledInput";
import styles from "./Repetitions.module.scss";
import clsx from "clsx";

export interface RepetitionsProps {
  value: number;
  onValueChange: (value: number) => void;
}

export const Repetitions = ({ value, onValueChange }: RepetitionsProps) => {
  const [focused, setFocused] = useState(false);

  return (
    <div className={clsx(styles.container, focused && styles.focused)}>
      <NumericUncontrolledInput
        className={styles.input}
        min={0}
        max={300}
        type="integer"
        value={value}
        onChange={onValueChange}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};
