import { ReactNode } from "react";
import styles from "./PageSection.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";

export interface PageSectionProps {
  label?: ReactNode;
  align?: "start" | "center" | "end";
  description?: ReactNode;
  back?: string;
  more?: ReactNode;
  children: ReactNode;
}

export const PageSection = ({
  back,
  label,
  align,
  description,
  more,
  children,
}: PageSectionProps) => (
  <div className={styles.section}>
    {label && (
      <div
        className={clsx("ui-row", styles.heading)}
        style={{ gap: "1rem", justifyContent: align }}
      >
        {back && (
          <Link className={styles.back} to={back}>
            <i className="ri-arrow-left-line"></i>
          </Link>
        )}

        <div>
          <h3 className={styles.label}>{label}</h3>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        {more && (
          <>
            <div style={{ flex: 1 }} />
            <div>{more}</div>
          </>
        )}
      </div>
    )}

    {children}
  </div>
);
