import { PageSection } from "../../app/PageSection";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper";
import { WorkoutLink } from "./WorkoutLink";
// import { usePrograms } from "../../db/Program.db";
// import { ProgramLink } from "../../components/Program_Link/ProgramLink";
import { AppPage } from "../../app/AppPage/AppPage";
import { useGetWorkouts } from "../../lib/_workout";
import { useCreateProgramMutation } from "graphql/types";

export const Root = () => {
  return <Base />;
};

const Base = () => {
  const { user } = useAuthenticatedWrapper();

  const navigate = useNavigate();
  const workouts = useGetWorkouts(user);
  // const programs = usePrograms(user);

  const [createProgramMutation] = useCreateProgramMutation();

  return (
    <AppPage>
      <PageSection
        label="Workouts"
        // description="Tracking workouts keeps you focused, inspired and determined"
        more={
          <div className="ui-row">
            <button
              className="button button--secondary button--small"
              onClick={() => {
                navigate("/start");
              }}
            >
              New workout
            </button>
          </div>
        }
      >
        <div style={{ marginTop: "0.75rem", gap: "1rem" }} className="ui-stack">
          {workouts.value?.map((workout, index) => (
            <WorkoutLink workout={workout} key={index} />
          ))}
        </div>
      </PageSection>

      <PageSection
        label="Plans"
        more={
          <div className="ui-row">
            <button
              className="button button--secondary button--small"
              onClick={async () => {
                const response = await createProgramMutation({
                  variables: {
                    input: {
                      name: "New program",
                    },
                  },
                });

                const id = response.data?.createProgram.id;

                if (id) {
                  navigate(`/programs/${id}`);
                }
              }}
            >
              New plan
            </button>
          </div>
        }
      >
        {/* <div className="ui-stack" style={{ gap: "1rem" }}>
          {programs.value?.map((program) => (
            <ProgramLink
              key={program.id}
              href={
                user?.uid === program.owner
                  ? `/programs/${program.id}`
                  : `/schedule/${program.id}`
              }
              description={program.description}
              program={program}
            />
          ))}
        </div> */}
      </PageSection>
    </AppPage>
  );
};
