import { useGetGroupsQuery } from "graphql/types";
import { Avatar } from "components/Avatar/Avatar";

import styles from "./Groups.module.scss";

export const Groups = () => {
  const { data } = useGetGroupsQuery();

  return (
    <div className={styles.layout}>
      <div className={styles.layoutTitle}>
        <h1>Groups</h1>

        <button className="button">Create new group</button>
      </div>

      {data?.memberships.map(({ group }) => (
        <div className={styles.group}>
          <div className={styles.title}>
            <h2>{group.name}</h2>
          </div>

          <div>
            {group.members.map((member) => (
              <div className={styles.member} key={member.id}>
                <Avatar size={42} photo={member.user.photo}></Avatar>
                <div className={styles.name}>{member.user.name}</div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="row" style={{ gap: "0.5rem" }}>
        <div className={styles.addMember}>Add member</div>
      </div>
    </div>
  );
};
