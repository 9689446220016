import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBebTnD97hHRmqQnHXAJzhmnxS5C6Lp_NM",
  authDomain: "bbb-b4fc1.firebaseapp.com",
  databaseURL:
    "https://bbb-b4fc1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bbb-b4fc1",
  storageBucket: "bbb-b4fc1.appspot.com",
  messagingSenderId: "245005365702",
  appId: "1:245005365702:web:d5b2bd2879544f9c9fc2c7",
  measurementId: "G-C59RW47VZ7",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
