import { round } from "lodash";
import {
  IVariable as IOldVariable,
  ResistanceType,
  SetStatus,
  VariableType,
} from "./types";
import { IWorkoutSet } from "./_workout";
import { ISessionSet, IVariable as INewVariable } from "routes/Session/schemas";

type ISetGeneric = IWorkoutSet | ISessionSet;
type IVariable = IOldVariable | INewVariable;

export const WorkoutSet = {
  getLabel(set: ISetGeneric, variables: IVariable[]) {
    const { weight, reps } = WorkoutSet.prepareWeightReps(set);

    switch (weight.type) {
      case ResistanceType.Bodyweight:
        return `${reps.value} x Bodyweight`;

      case ResistanceType.Units:
        return `${reps.value} x ${round(weight.value)}kg`;

      case ResistanceType.Percentage: {
        const value = WorkoutSet.computeNominalWeight(set, variables);

        return `${reps.value} x ${value}kg (${weight.value}% 1RM)`;
      }

      default:
        return `${reps.value} reps`;
    }
  },

  progress(sets: ISetGeneric[]) {
    if (sets.length === 0) {
      return 0;
    }

    const done = sets.filter((v) => v.status === SetStatus.Done);

    return (done.length / sets.length) * 100;
  },

  modified(set: ISetGeneric): boolean {
    return !!(set.overwriteWeight || set.overwriteReps);
  },

  computeNominalWeight(set: ISetGeneric, variables?: IVariable[]) {
    const { weight } = WorkoutSet.prepareWeightReps(set);

    if (weight.type !== ResistanceType.Percentage) {
      return weight.value;
    }

    const variable = variables?.find(
      (v) => v.type === VariableType.RepetitionMax
    );

    if (!variable) {
      console.warn("Cannot find variable for 1 rep max.");
      return 0;
    }

    const result = round((variable.value / 100) * set.weight.value, 2);

    if (Number.isNaN(result)) {
      return 0;
    }

    return result;
  },

  prepareWeightReps(set: ISetGeneric) {
    const weight = set.overwriteWeight || set.weight;
    const reps = set.overwriteReps || set.reps;

    return { weight, reps } as const;
  },

  compute1RM(weight: number, reps: number) {
    return weight / (1.0278 - 0.0278 * reps);
  },
};
