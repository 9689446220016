import { Link } from "react-router-dom";
import { useAuthenticatedWrapper } from "../../app/AuthenticatedWrapper";
import clsx from "clsx";
import { matrix } from "../../utils";
import { IWorkoutCycle } from "../../lib/_workout";
import { lib } from "../../lib/_lib";

import styles from "./WorkoutLink.module.scss";

export interface Props {
  workout: IWorkoutCycle;
}

const generateHeatmap = (workout: IWorkoutCycle) => {
  return matrix(workout.program.days, workout.program.weeks).map((coords) => {
    const key = coords.join("-");
    const schedule = workout.schedule[key] || [];

    const sets = schedule
      .flatMap((id) => workout.exercises[id]?.sets)
      .filter(Boolean)
      .map((id) => workout.sets[id]);

    const progress = lib.WorkoutSet.progress(sets);
    const done = sets.length !== 0 && progress === 100;
    const total = sets.length;

    return {
      progress,
      total,
      done,
    };
  });
};

export const WorkoutLink = ({ workout }: Props) => {
  const { user } = useAuthenticatedWrapper();

  const heatmap = generateHeatmap(workout);

  return (
    <Link className={styles.component} to={`/workouts/${workout.id}`}>
      <div style={{ flex: 1 }}>
        <div className={styles.title}>{workout.name}</div>
        <div className={styles.description}>
          {/* {user && user.uid === workout.userId ? "You" : "xxx"}
          Following {cycle.program.name} plan */}
        </div>
      </div>

      <div
        className={styles.cells}
        style={{ gridTemplateColumns: `repeat(${workout.program.days}, 10px)` }}
      >
        {heatmap.map((segment, index) => (
          <div
            key={index}
            className={clsx(
              styles.cell,
              segment.done
                ? styles.cellDone
                : segment.progress === 0
                ? styles.cellEmpty
                : styles.cellSome
            )}
          ></div>
        ))}
      </div>
    </Link>
  );
};
