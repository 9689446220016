import { ReactNode } from "react";
import { Header } from "./Header";

import styles from "./AppPage.module.scss";

export interface AppPageProps {
  backHref?: string;
  more?: ReactNode;
  children: ReactNode;
}

export const AppPage = ({ more, backHref, children }: AppPageProps) => {
  return (
    <div className={styles.app}>
      <Header more={more} backHref={backHref} />
      <main className={styles.content}>{children}</main>
    </div>
  );
};
